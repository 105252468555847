<template>
  <div>
    <v-btn
      class="border-shaped ml-2 yellow--bg"
      depressed
      @click="toggleDialog"
    >
      <v-icon v-if="item">mdi-pencil</v-icon>
      <v-icon v-else>mdi-plus</v-icon>
    </v-btn>
    <v-dialog v-model="dialog" max-width="900px">
      <v-form
        ref="formEditPapier"
        v-model="valid"
        @submit.prevent="submitPapier"
      >
        <v-card elevation="24" shaped>
          <v-card-title class="text-h4 text-lg-h3 font-weight-bold">
            {{ item ? 'Éditer Papier' : 'Ajouter Papier' }}
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-text-field
              v-model="name"
              class="border-shaped"
              clearable
              label="Libellé"
              outlined
            ></v-text-field>
            <v-text-field
              v-model="en_name"
              class="border-shaped"
              clearable
              label="Libellé Anglais"
              outlined
            ></v-text-field>
            <v-text-field
              v-model="file_name"
              class="border-shaped"
              clearable
              label="Libellé Fichier"
              outlined
            ></v-text-field>
            <v-text-field
              v-model="weight"
              class="border-shaped"
              clearable
              label="Poids"
              outlined
            ></v-text-field>
            <v-text-field
              v-model="epaisseur"
              class="border-shaped"
              clearable
              label="Epaisseur"
              outlined
            ></v-text-field>
            <v-text-field
              v-model="price"
              class="border-shaped"
              clearable
              label="Prix"
              outlined
            ></v-text-field>
            <v-select
              v-model="impression_id"
              :items="impression_items"
              class="border-shaped"
              item-text="name"
              item-value="id"
              label="Impression"
              outlined
            ></v-select>
            <v-text-field
              v-model="min_exemplaires"
              label="Min Exemplaires"
              class="border-shaped"
              clearable
              outlined
            ></v-text-field>
            <v-text-field
              v-model="min_exemplaires_couleur"
              label="Min Exemplaires Couleur"
              class="border-shaped"
              clearable
              outlined
            ></v-text-field>
            <v-text-field
              v-model="max_exemplaires"
              label="Max Exemplaires"
              class="border-shaped"
              clearable
              outlined
            ></v-text-field>
            <v-text-field
              v-model="max_exemplaires_couleur"
              label="Max Exemplaires Couleur"
              class="border-shaped"
              clearable
              outlined
            ></v-text-field>
            <div>
              <v-switch
                v-model="is_creation"
                label="Papier Création"
                class="mt-n3"
              ></v-switch>
              <v-switch
                v-model="enabled"
                label="Activé/Désactivé"
                class="mt-n3"
              ></v-switch>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-btn
              :loading="loading"
              class="border-shaped yellow--bg ml-auto"
              depressed
              large
              type="submit"
            >
              Valider
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios'
import { EventBus } from '@/utils'

export default {
  props: ['item', 'typePapier'],
  data() {
    return {
      dialog: false,
      valid: false,
      loading: false,
      name: this.item ? this.item.name : '',
      en_name: this.item ? this.item.en_name : '',
      file_name: this.item ? this.item.file_name : '',
      weight: this.item ? parseFloat(this.item.weight) : 0,
      epaisseur: this.item ? parseFloat(this.item.epaisseur) : 0,
      price: this.item ? parseFloat(this.item.price) : 0,
      impression_id: this.item
        ? this.item.impression
          ? parseInt(this.item.impression.id)
          : 0
        : 0,
      impression_items: [],
      min_exemplaires: this.item ? this.item.min_exemplaires : 1,
      min_exemplaires_couleur: this.item
        ? this.item.min_exemplaires_couleur
        : 1,
      max_exemplaires: this.item ? this.item.max_exemplaires : 2000,
      max_exemplaires_couleur: this.item
        ? this.item.max_exemplaires_couleur
        : 2000,
      enabled: this.item ? this.item.enabled : true,
      is_creation: this.item ? this.item.is_creation : false,
    }
  },
  methods: {
    toggleDialog() {
      this.dialog = !this.dialog
      if (this.dialog) {
        this.getImpressions()
      }
    },
    submitPapier() {
      this.$refs.formEditPapier.validate()
      if (this.valid) {
        this.loading = true
        const data = {
          name: this.name,
          en_name: this.en_name,
          file_name: this.file_name,
          weight: this.weight,
          epaisseur: this.epaisseur,
          price: this.price,
          impression_id: this.impression_id,
          enabled: this.enabled,
          min_exemplaires: this.min_exemplaires,
          min_exemplaires_couleur: this.min_exemplaires_couleur,
          max_exemplaires: this.max_exemplaires,
          max_exemplaires_couleur: this.max_exemplaires_couleur,
          is_creation: this.is_creation,
        }
        if (this.item) {
          axios
            .put(
              process.env.VUE_APP_API_URL +
                `/admin/${this.typePapier}/${this.item.id}`,
              data,
              {
                headers: {
                  Authorization: `Bearer ${this.$store.getters.getJwtToken}`,
                },
              }
            )
            .then(() => {
              EventBus.$emit(`refresh${this.typePapier}`)
              switch (this.typePapier) {
                case 'papiersinterieur':
                  EventBus.$emit('notif', 'Papier Intérieur modifié', 'success')
                  break
                case 'papierscouverture':
                  EventBus.$emit(
                    'notif',
                    'Papier Couverture modifié',
                    'success'
                  )
                  break
                default:
                  break
              }
              this.dialog = false
              this.loading = false
            })
            .catch(() => {
              this.loading = false
            })
        } else {
          axios
            .post(
              process.env.VUE_APP_API_URL + `/admin/${this.typePapier}`,
              data,
              {
                headers: {
                  Authorization: `Bearer ${this.$store.getters.getJwtToken}`,
                },
              }
            )
            .then(() => {
              EventBus.$emit(`refresh${this.typePapier}`)
              switch (this.typePapier) {
                case 'papiersinterieur':
                  EventBus.$emit('notif', 'Papier Intérieur créé', 'success')
                  break
                case 'papierscouverture':
                  EventBus.$emit('notif', 'Papier Couverture créé', 'success')
                  break
                default:
                  break
              }
              this.dialog = false
              this.loading = false
            })
            .catch(() => {
              this.loading = false
            })
        }
      }
    },
    getImpressions() {
      axios
        .get(process.env.VUE_APP_API_URL + '/impression', {
          headers: {
            Authorization: `Bearer ${this.$store.getters.getJwtToken}`,
          },
        })
        .then((r) => {
          this.impression_items = r.data.impressions
          this.impression_items.unshift({
            id: 0,
            name: 'Aucune',
          })
        })
        .catch(() => {})
    },
  },
}
</script>

<style lang="scss" scoped></style>
