<template>
  <div>
    <v-btn class="yellow--bg border-shaped" depressed @click="toggleDialog">
      <v-icon>mdi-pencil</v-icon>
    </v-btn>
    <v-dialog v-model="dialog" :persistent="loading" max-width="1200px">
      <v-form ref="formProduct" @submit.prevent="createProduct">
        <v-stepper v-model="page" elevation="24" shaped>
          <v-stepper-header>
            <v-stepper-step
              :complete="page > 1"
              :rules="premierOngletRules"
              color="yellow"
              editable
              step="1"
            >
              {{ $t("product.global") }}
            </v-stepper-step>
            <v-divider></v-divider>

            <v-stepper-step
              :complete="page > 2"
              :rules="secondOngletRules"
              color="yellow"
              editable
              step="2"
            >
              {{ $t("product.format_and_impression") }}
            </v-stepper-step>
            <v-divider></v-divider>

            <v-stepper-step
              :complete="page > 3"
              :rules="troisiemeOngletRules"
              color="yellow"
              editable
              step="3"
            >
              {{ $t("product.inner") }}
            </v-stepper-step>
            <v-divider></v-divider>

            <v-stepper-step
              :complete="page > 4"
              :rules="quatriemeOngletRules"
              color="yellow"
              editable
              step="4"
            >
              {{ $t("product.cover") }}
            </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="titre"
                      :rules="titreRules"
                      class="border-shaped"
                      :label="$t('product.title')"
                      outlined
                      required
                    ></v-text-field>
                    <v-text-field
                      v-model="auteur"
                      :rules="auteurRules"
                      class="border-shaped"
                      :label="$t('product.author')"
                      outlined
                    ></v-text-field>
                    <v-text-field
                      v-model="isbn"
                      :rules="isbnRules"
                      class="border-shaped"
                      :hint="$t('form.hint.product.isbn')"
                      label="ISBN (EAN13)"
                      outlined
                      persistent-hint
                    ></v-text-field>
                    <v-text-field
                      v-model="reference"
                      :rules="referenceRules"
                      class="border-shaped"
                      :label="$t('product.reference')"
                      outlined
                    ></v-text-field>
                    <v-select
                      v-model="finition"
                      :items="finition_items"
                      :rules="finitionRules"
                      class="border-shaped"
                      :label="$t('product.finition')"
                      :item-text="
                        (item) =>
                          $i18n.locale === 'fr' ? item.name : item.en_name
                      "
                      outlined
                      required
                      return-object
                    ></v-select>
                    <v-select
                      v-model="acheve"
                      :items="acheve_items"
                      :rules="acheveRules"
                      class="border-shaped"
                      :label="$t('product.acheve')"
                      :item-text="
                        (item) =>
                          $i18n.locale === 'fr' ? item.name : item.en_name
                      "
                      outlined
                      required
                      return-object
                    >
                      <template #item="{ item }">
                        <v-list-item-icon v-if="item.id > 1">
                          <v-img
                            v-if="item.id == 2"
                            contain
                            lazy-src="@/assets/img/acheves/Achevé avec date.svg"
                            src="@/assets/img/acheves/Achevé avec date.svg"
                            width="150"
                          ></v-img>
                          <v-img
                            v-if="item.id == 3"
                            contain
                            lazy-src="@/assets/img/acheves/Achevé sans date.svg"
                            src="@/assets/img/acheves/Achevé sans date.svg"
                            width="150"
                          ></v-img>
                          <v-img
                            v-if="item.id == 4"
                            contain
                            lazy-src="@/assets/img/acheves/Achevé avec date sans ajout de page.svg"
                            src="@/assets/img/acheves/Achevé avec date sans ajout de page.svg"
                            width="150"
                          ></v-img>
                          <v-img
                            v-if="item.id == 5"
                            contain
                            lazy-src="@/assets/img/acheves/Achevé sans date sans ajout de page.svg"
                            src="@/assets/img/acheves/Achevé sans date sans ajout de page.svg"
                            width="150"
                          ></v-img>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{
                              $i18n.locale === "fr" ? item.name : item.en_name
                            }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </v-select>
                    <v-select
                      v-model="category"
                      :items="category_items"
                      :rules="categoryRules"
                      class="border-shaped"
                      :label="$t('product.category')"
                      :item-text="
                        (item) =>
                          $i18n.locale === 'fr'
                            ? item.name
                            : item.en_name +
                              ' - ' +
                              $t('order.price.tva') +
                              ' : ' +
                              item.tva +
                              '%'
                      "
                      outlined
                      required
                      return-object
                    >
                    </v-select>
                    <v-select
                      v-model="f1"
                      :items="etat_items"
                      :rules="etatRules"
                      class="border-shaped"
                      item-text="name"
                      :label="$t('product.status')"
                      outlined
                      required
                      return-object
                    ></v-select>
                    <v-text-field
                      v-model="version"
                      :rules="versionRules"
                      class="border-shaped"
                      :label="$t('product.version')"
                      outlined
                      required
                      type="number"
                    ></v-text-field>
                    <v-switch
                      v-model="enabled"
                      class="mt-n2"
                      label="Activé/Désactivé"
                    ></v-switch>
                    <v-switch
                      v-model="f2"
                      class="mt-n2"
                      label="Envoyé au serveur/Non envoyé au serveur"
                    ></v-switch>
                  </v-col>
                  <v-col align="right" cols="12">
                    <v-lazy transition="slide-y-transition">
                      <div>
                        <v-btn
                          class="yellow--bg border-shaped mt-n5"
                          elevation="0"
                          large
                          @click="page = 2"
                        >
                          {{ $t("global.next") }}
                        </v-btn>
                      </div>
                    </v-lazy>
                  </v-col>
                </v-row>
              </v-container>
            </v-stepper-content>
            <v-stepper-content step="2">
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-select
                      v-model="impression"
                      :items="impression_items_filtered"
                      :rules="impressionRules"
                      class="border-shaped"
                      :label="$t('product.impression')"
                      :item-text="
                        (item) =>
                          $i18n.locale === 'fr' ? item.name : item.en_name
                      "
                      outlined
                      required
                      return-object
                      @change="updateImpression"
                    ></v-select>
                    <v-switch
                      v-model="enableFormat"
                      class="mt-n1 mr-2"
                      :label="$t('product.custom_format')"
                      @change="updateFormat"
                    ></v-switch>
                    <v-select
                      v-if="!enableFormat"
                      v-model="format"
                      :items="format_items"
                      :rules="formatRules"
                      class="border-shaped"
                      item-text="name"
                      :label="$t('product.format')"
                      outlined
                      required
                      return-object
                    ></v-select>
                    <v-input v-if="enableFormat">
                      <v-text-field
                        v-model="width"
                        :rules="widthRules"
                        class="border-shaped mr-3"
                        :hint="$t('form.hint.product.width', [100, 210])"
                        :label="$t('product.width')"
                        outlined
                        persistent-hint
                        required
                        type="number"
                      ></v-text-field>
                      <v-text-field
                        v-model="height"
                        :rules="heightRules"
                        class="border-shaped ml-3"
                        :hint="$t('form.hint.product.height', [160, 297])"
                        :label="$t('product.height')"
                        outlined
                        persistent-hint
                        required
                        type="number"
                      ></v-text-field>
                    </v-input>
                  </v-col>
                  <v-col align="right" cols="12">
                    <v-lazy transition="slide-y-transition">
                      <div>
                        <v-btn
                          class="yellow--bg border-shaped mt-n5 mr-2"
                          elevation="0"
                          large
                          @click="page = 1"
                        >
                          {{ $t("global.previous") }}
                        </v-btn>
                        <v-btn
                          class="yellow--bg border-shaped mt-n5"
                          elevation="0"
                          large
                          @click="page = 3"
                        >
                          {{ $t("global.next") }}
                        </v-btn>
                      </div>
                    </v-lazy>
                  </v-col>
                </v-row>
              </v-container>
            </v-stepper-content>
            <v-stepper-content step="3">
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-select
                      v-model="papier_interieur"
                      :items="papier_interieur_items"
                      :rules="papierInterieurRules"
                      class="border-shaped"
                      :label="$t('product.inner_paper')"
                      :item-text="
                        (item) =>
                          $i18n.locale === 'fr'
                            ? item.papier.name
                            : item.papier.en_name
                      "
                      outlined
                      required
                      return-object
                    ></v-select>
                    <v-switch
                      v-model="bords_perdus"
                      class="mt-n3"
                      :label="$t('product.lost_edges')"
                    ></v-switch>
                    <v-dialog v-model="bords_perdus_dialog" width="auto">
                      <v-card elevation="24" shaped>
                        <v-card-title class="mb-2">
                          <v-icon class="warning--text text-h5 mr-2" dense
                            >mdi-alert</v-icon
                          >
                          {{ $t("global.caution") }}
                        </v-card-title>
                        <v-card-subtitle>
                          {{ $t("product.message.lost_edges") }} :
                        </v-card-subtitle>
                        <v-card-text class="text-body-1">
                          <v-switch
                            v-model="bords_perdus"
                            class=""
                            :label="$t('product.lost_edges')"
                          ></v-switch>
                        </v-card-text>
                        <v-card-actions class="d-flex justify-end w-100">
                          <v-btn
                            class="yellow--bg border-shaped px-3"
                            elevation="0"
                            large
                            @click="bords_perdus_dialog = false"
                          >
                            {{ $t("global.close") }}
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                    <v-file-input
                      v-show="pdf_interieur"
                      ref="pdfInterieur"
                      v-model="pdf_interieur"
                      :rules="pdfInterieurRules"
                      accept="application/pdf"
                      chips
                      class="border-shaped"
                      clearable
                      :label="$t('product.pdf.inner')"
                      outlined
                      prepend-icon=""
                      prepend-inner-icon="mdi-file-document-outline"
                      required
                      show-size
                    ></v-file-input>
                    <v-btn
                      v-show="!pdf_interieur"
                      block
                      class="yellow--bg border-shaped mb-5 py-16"
                      depressed
                      style="
                        border-style: dashed;
                        border-width: 2px;
                        border-color: gray;
                      "
                      x-large
                      @click="
                        $refs.pdfInterieur.$el.querySelector('input').click()
                      "
                      @drop="readPDFInterieur"
                      @dragover.prevent
                    >
                      {{ $t("product.pdf.upload_inner") }}
                    </v-btn>
                    <v-lazy>
                      <v-alert
                        v-if="finition?.id === 2 && !pdf_interieur"
                        type="warning"
                        text
                        dense
                        class="border-shaped text-caption"
                      >
                        {{ $t("product.message.pique_by_4") }}
                      </v-alert>
                    </v-lazy>
                    <v-lazy>
                      <v-alert
                        v-if="finition?.id === 2 && pdfIntPageCount % 4 !== 0"
                        type="error"
                        text
                        dense
                        class="border-shaped text-caption"
                        icon="mdi-close"
                      >
                        {{ $t("product.message.pique_by_4") }}
                      </v-alert>
                    </v-lazy>
                    <v-lazy>
                      <v-alert
                        v-if="
                          finition?.id === 2 &&
                          (pdfIntPageCount < 4 || pdfIntPageCount > 80)
                        "
                        type="error"
                        text
                        dense
                        class="border-shaped text-caption"
                        icon="mdi-close"
                      >
                        {{ $t("error.product.pdf.pique_page_count", [4, 80]) }}
                      </v-alert>
                    </v-lazy>
                    <v-lazy>
                      <v-alert
                        v-if="pdfIntError"
                        type="error"
                        text
                        dense
                        class="border-shaped text-caption"
                        icon="mdi-close"
                      >
                        {{ $t("error.product.pdf.detect") }}
                      </v-alert>
                    </v-lazy>
                    <v-lazy>
                      <v-alert
                        type="warning"
                        dense
                        text
                        class="border-shaped"
                        v-if="impression?.id === 2 && pdfIntPageCount % 4 !== 0"
                      >
                        {{ $t("product.message.print_color_page_count") }}
                      </v-alert>
                    </v-lazy>
                    <v-lazy>
                      <div v-if="pdfIntFormat">
                        <div v-if="format">
                          <v-alert
                            v-if="
                              parseFloat(format.width) ===
                                parseFloat(pdfIntFormat.width) &&
                              parseFloat(format.height) ===
                                parseFloat(pdfIntFormat.height)
                            "
                            type="success"
                            text
                            dense
                            class="border-shaped text-caption"
                            icon="mdi-check"
                          >
                            {{
                              $t("detect.inner.success", [
                                parseFloat(format.width),
                                parseFloat(format.height),
                                parseFloat(pdfIntFormat.width),
                                parseFloat(pdfIntFormat.height),
                              ])
                            }}
                          </v-alert>
                          <v-alert
                            icon="mdi-close"
                            type="error"
                            class="border-shaped text-caption"
                            text
                            dense
                            v-else
                          >
                            {{
                              $t("detect.inner.error", [
                                parseFloat(format.width),
                                parseFloat(format.height),
                                parseFloat(pdfIntFormat.width),
                                parseFloat(pdfIntFormat.height),
                              ])
                            }}
                            <v-tooltip top>
                              <template #activator="{ on, attrs }">
                                <v-btn
                                  class="yellow--bg border-shaped ml-2 black--text"
                                  depressed
                                  small
                                  v-bind="attrs"
                                  @click="setDetectedFormat"
                                  v-on="on"
                                  >{{ $t("fix") }}
                                </v-btn>
                              </template>
                              <span>{{ $t("tooltip_fix") }}</span>
                            </v-tooltip>
                          </v-alert>
                        </div>
                        <div v-if="width && height">
                          <v-alert
                            v-if="
                              parseFloat(width) ===
                                parseFloat(pdfIntFormat.width) &&
                              parseFloat(height) ===
                                parseFloat(pdfIntFormat.height)
                            "
                            type="success"
                            text
                            dense
                            class="border-shaped text-caption"
                            icon="mdi-check"
                          >
                            {{
                              $t("detect.inner.success", [
                                parseFloat(width),
                                parseFloat(height),
                                parseFloat(pdfIntFormat.width),
                                parseFloat(pdfIntFormat.height),
                              ])
                            }}
                          </v-alert>
                          <v-alert
                            icon="mdi-close"
                            type="error"
                            text
                            dense
                            class="border-shaped text-caption"
                            v-else
                          >
                            {{
                              $t("detect.inner.error", [
                                parseFloat(width),
                                parseFloat(height),
                                parseFloat(pdfIntFormat.width),
                                parseFloat(pdfIntFormat.height),
                              ])
                            }}
                            <v-tooltip top>
                              <template #activator="{ on, attrs }">
                                <v-btn
                                  class="yellow--bg border-shaped ml-2 black--text"
                                  depressed
                                  small
                                  v-bind="attrs"
                                  @click="setDetectedFormat"
                                  v-on="on"
                                  >{{ $t("fix") }}
                                </v-btn>
                              </template>
                              <span>{{ $t("tooltip_fix") }}</span>
                            </v-tooltip>
                          </v-alert>
                        </div>
                        <v-alert
                          v-if="!format && (!width || !height)"
                          type="error"
                          text
                          dense
                          class="border-shaped text-caption"
                          icon="mdi-close"
                        >
                          {{ $t("please_select_format") }}
                        </v-alert>
                      </div>
                    </v-lazy>
                    <v-lazy>
                      <div
                        v-if="loadingInt"
                        class="text-caption secondary--text"
                      >
                        <v-progress-circular
                          :size="20"
                          :width="2"
                          color="secondary"
                          indeterminate
                        ></v-progress-circular>
                        {{ $t("detect_processing") }}
                      </div>
                    </v-lazy>
                    <v-lazy>
                      <div v-if="pdfCouvFormat && pdfIntFormat">
                        <v-alert
                          v-if="isIntCouvMatch"
                          type="success"
                          text
                          dense
                          class="border-shaped text-caption"
                          icon="mdi-check"
                        >
                          {{ $t("detect.inner_cover.match") }}
                        </v-alert>
                        <v-alert
                          v-else
                          type="error"
                          text
                          dense
                          class="border-shaped text-caption"
                          icon="mdi-close"
                        >
                          {{
                            $t("detect.inner_cover.not_match", [
                              pdfIntFormat.width,
                              pdfIntFormat.height,
                              Math.round((pdfCouvFormat.width - dos) / 2),
                              pdfCouvFormat.height,
                              Math.round(
                                dos +
                                  (pdfIntFormat.width - pdfCouvFormat.width / 2)
                              ),
                              pdfIntFormat.width * 2 + dos,
                              pdfIntFormat.height,
                            ])
                          }}
                        </v-alert>
                      </div>
                    </v-lazy>
                    <v-textarea
                      v-model="comment"
                      counter
                      :label="$t('product.comment')"
                      outlined
                    ></v-textarea>
                  </v-col>
                  <v-col align="right" cols="12">
                    <v-lazy transition="slide-y-transition">
                      <div>
                        <v-btn
                          class="yellow--bg border-shaped mt-n5 mr-2"
                          elevation="0"
                          large
                          @click="page = 2"
                        >
                          {{ $t("global.previous") }}
                        </v-btn>
                        <v-btn
                          class="yellow--bg border-shaped mt-n5"
                          elevation="0"
                          large
                          @click="page = 4"
                        >
                          {{ $t("global.next") }}
                        </v-btn>
                      </div>
                    </v-lazy>
                  </v-col>
                </v-row>
              </v-container>
            </v-stepper-content>
            <v-stepper-content step="4">
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-select
                      v-model="papier_couverture"
                      :items="papier_couverture_items"
                      :rules="papierCouvertureRules"
                      class="border-shaped"
                      :label="$t('product.cover_paper')"
                      :item-text="
                        (item) =>
                          $i18n.locale === 'fr'
                            ? item.papier.name
                            : item.papier.en_name
                      "
                      outlined
                      required
                      return-object
                    ></v-select>
                    <v-select
                      v-model="pelliculage"
                      :items="pelliculage_items"
                      :rules="pelliculageRules"
                      class="border-shaped"
                      :label="$t('product.lamination')"
                      :item-text="
                        (item) =>
                          $i18n.locale === 'fr' ? item.name : item.en_name
                      "
                      :hint="
                        papier_couverture?.papier.is_creation
                          ? $t('papier_creation')
                          : ''
                      "
                      persistent-hint
                      outlined
                      return-object
                    ></v-select>
                    <v-switch
                      v-model="recto_verso"
                      class="mt-n3"
                      :label="$t('product.recto_verso')"
                    ></v-switch>
                    <v-file-input
                      v-show="pdf_couverture"
                      ref="pdfCouverture"
                      v-model="pdf_couverture"
                      :rules="pdfCouvertureRules"
                      accept="application/pdf"
                      chips
                      class="border-shaped"
                      clearable
                      :label="$t('product.pdf.cover')"
                      outlined
                      prepend-icon=""
                      prepend-inner-icon="mdi-file-outline"
                      show-size
                    ></v-file-input>
                    <v-btn
                      v-show="!pdf_couverture"
                      block
                      class="yellow--bg border-shaped mb-5 py-16"
                      depressed
                      style="
                        border-style: dashed;
                        border-width: 2px;
                        border-color: gray;
                      "
                      x-large
                      @click="
                        $refs.pdfCouverture.$el.querySelector('input').click()
                      "
                      @drop="readPDFCouverture"
                      @dragover.prevent
                    >
                      {{ $t("product.pdf.upload_cover") }}
                    </v-btn>
                    <v-switch
                      v-if="finition?.id === 2 && !pdf_couverture"
                      v-model="delete_couverture"
                      class="mt-n3"
                      :label="$t('product.message.delete_couverture')"
                    ></v-switch>
                    <v-lazy>
                      <v-alert
                        v-if="pdfCouvError"
                        type="error"
                        text
                        dense
                        class="border-shaped text-caption"
                        icon="mdi-close"
                      >
                        {{ $t("detect.cover.error.invalid") }}
                      </v-alert>
                      <v-alert
                        v-if="pdfCouvCount > 2"
                        type="error"
                        text
                        dense
                        class="border-shaped text-caption"
                        icon="mdi-close"
                      >
                        <span
                          v-html="
                            ' ' + $t('detect.cover.error.more', [pdfCouvCount])
                          "
                        ></span>
                      </v-alert>
                      <v-alert
                        v-if="pdfCouvCount === 2"
                        type="warning"
                        text
                        dense
                        class="border-shaped text-caption"
                        icon="mdi-alert"
                      >
                        <span
                          v-html="
                            ' ' + $t('detect.cover.error.two', [pdfCouvCount])
                          "
                        ></span>
                      </v-alert>
                      <v-alert
                        v-if="pdfCouvCount === 1"
                        type="success"
                        text
                        dense
                        class="border-shaped text-caption"
                        icon="mdi-check"
                      >
                        <span
                          v-html="
                            ' ' + $t('detect.cover.success', [pdfCouvCount])
                          "
                        ></span>
                      </v-alert>
                      <div
                        v-if="loadingCouv"
                        class="text-caption secondary--text"
                      >
                        <v-progress-circular
                          :size="20"
                          :width="2"
                          color="secondary"
                          indeterminate
                        ></v-progress-circular>
                        {{ $t("detect.cover.warning") }}
                      </div>
                    </v-lazy>
                    <v-lazy>
                      <div v-if="pdfCouvFormat && pdfCouvBleedbox">
                        <v-alert
                          v-if="
                            pdfCouvFormat.width > pdfCouvBleedbox.width ||
                            pdfCouvFormat.height > pdfCouvBleedbox.height
                          "
                          type="error"
                          text
                          dense
                          class="border-shaped text-caption"
                          icon="mdi-close"
                        >
                          {{
                            $t("detect.cover_bleed.error", [
                              pdfCouvFormat.width,
                              pdfCouvFormat.height,
                              pdfCouvBleedbox.width,
                              pdfCouvBleedbox.height,
                            ])
                          }}
                        </v-alert>
                        <v-alert
                          v-else
                          type="success"
                          text
                          dense
                          class="border-shaped text-caption"
                          icon="mdi-check"
                        >
                          {{
                            $t("detect.cover_bleed.success", [
                              pdfCouvFormat.width,
                              pdfCouvFormat.height,
                              pdfCouvBleedbox.width,
                              pdfCouvBleedbox.height,
                            ])
                          }}
                        </v-alert>
                      </div>
                    </v-lazy>
                    <v-lazy>
                      <div v-if="pdfCouvFormat && pdfIntFormat">
                        <v-alert
                          v-if="isIntCouvMatch"
                          type="success"
                          text
                          dense
                          class="border-shaped text-caption"
                          icon="mdi-check"
                        >
                          {{ $t("detect.inner_cover.match") }}
                        </v-alert>
                        <v-alert
                          v-else
                          type="error"
                          text
                          dense
                          class="border-shaped text-caption"
                          icon="mdi-close"
                        >
                          {{
                            $t("detect.inner_cover.not_match", [
                              pdfIntFormat.width,
                              pdfIntFormat.height,
                              Math.round((pdfCouvFormat.width - dos) / 2),
                              pdfCouvFormat.height,
                              Math.round(
                                dos +
                                  (pdfIntFormat.width - pdfCouvFormat.width / 2)
                              ),
                              pdfIntFormat.width * 2 + dos,
                              pdfIntFormat.height,
                            ])
                          }}
                        </v-alert>
                      </div>
                    </v-lazy>
                    <v-lazy>
                      <v-alert
                        v-if="
                          pdfIntPageCount > 0 &&
                          papier_couverture !== null &&
                          papier_interieur !== null
                        "
                        type="info"
                        text
                        dense
                        class="border-shaped text-caption"
                        icon="mdi-information"
                      >
                        {{ $t("calc_thickness", [dos]) }}
                      </v-alert>
                      <div v-else>
                        <v-alert
                          v-if="finition?.id === 1"
                          type="warning"
                          text
                          dense
                          class="border-shaped text-caption"
                          icon="mdi-information"
                        >
                          {{ $t("please_upload_inner") }}
                        </v-alert>
                      </div>
                    </v-lazy>
                  </v-col>
                  <v-col align="right" cols="12">
                    <v-lazy transition="slide-y-transition">
                      <div>
                        <v-btn
                          class="yellow--bg border-shaped mt-n5 mr-2"
                          elevation="0"
                          large
                          @click="page = 3"
                        >
                          {{ $t("global.previous") }}
                        </v-btn>
                        <v-btn
                          v-if="!loading"
                          :disabled="loadingCouv || loadingInt"
                          class="yellow--bg border-shaped mt-n5"
                          elevation="0"
                          large
                          type="submit"
                        >
                          {{ $t("form.submit") }}
                        </v-btn>
                      </div>
                    </v-lazy>
                  </v-col>
                </v-row>
              </v-container>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
        <v-lazy transition="slide-y-transition">
          <v-progress-linear
            v-if="loading"
            :value="progress"
            class="border-shaped mt-2"
            color="amber"
            height="50"
          >
            <template #default="{ value }">
              <strong v-if="progress < 100">{{ Math.ceil(value) }}%</strong>
              <strong v-else>
                <v-progress-circular
                  color="black"
                  indeterminate
                  size="20"
                ></v-progress-circular>
                {{ $t("processing") }}</strong
              >
            </template>
          </v-progress-linear>
        </v-lazy>
        <v-lazy transition="slide-y-transition">
          <v-card v-if="errors" class="mt-2 mr-2">
            <v-card-text>
              <v-list dense>
                <v-subheader>{{ $t("global.errors") }} :</v-subheader>
                <v-list-item
                  v-for="(e, i) in errors"
                  :key="i"
                  class="red--text text-justify"
                >
                  <v-list-item-icon>
                    <v-icon class="red--text">mdi-alert-circle</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>{{ e }}</v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </v-lazy>
      </v-form>
    </v-dialog>
  </div>
</template>

<script lang="js">

import axios from 'axios'
import { EventBus } from '@/utils'
import { PDFDocument } from 'pdf-lib'
import isNumeric from 'validator/lib/isNumeric'


export default {
  name: 'ProductAdminEdit',
  props: ['product'],
  data() {
    return {
      opened: false,
      dialog: null,
      submitted: false,
      loading: false,
      loadingCouv: false,
      loadingInt: false,
      errors: null,
      progress: 0,
      page: 1,
      titre: this.product.title,
      auteur: this.product.author,
      isbn: this.product.isbn,
      originalISBN: this.product.isbn,
      reference: this.product.reference,
      finition: this.product.finition,
      category: this.product.category,
      acheve: this.product.acheve,
      f1: this.product.f1,
      f2: this.product.f2,
      version: this.product.version,
      impression: this.product.impression,
      format: this.product.format,
      enableFormat: false,
      enabled: this.product.enabled,
      width: null,
      height: null,
      papier_couverture: this.product.papier_couverture,
      pelliculage: this.product.pelliculage,
      recto_verso: this.product.is_couv_recto_verso,
      pdf_couverture: null,
      delete_couverture: false,
      pdfCouvCount: 0,
      pdfCouvFormat: null,
      pdfCouvBleedbox: null,
      pdfCouvError: false,
      pdfIntPageCount: this.product.page_count,
      pdfIntFormat: null,
      papier_interieur: this.product.papier_interieur,
      bords_perdus: this.product.is_bords_perdus,
      bords_perdus_dialog: false,
      pdf_interieur: null,
      comment: this.product.comment !== 'null' ? this.product.comment : '',
      finition_items: [],
      category_items: [],
      acheve_items: [],
      etat_items: [],
      impression_items: [],
      format_items: [],
      papier_couverture_items: [],
      papier_couverture_items_full: [],
      pelliculage_items: [],
      papier_interieur_items: [],
      papier_interieur_items_full: [],
      titreRules: [() => (this.titre.trim().length > 0) || this.$t('form.required.product.title')],
      auteurRules: [],
      isbnRules: [
        () => {
          if (this.isbn === this.originalISBN) {
            return true
          }

          if (!this.isbn && !this.reference) {
            return this.$t('isbn.or_ref')
          }

          if (!this.isbn && !!this.reference.trim()) {
            return true
          }

          if (!isNumeric(this.isbn.trim())) {
            return this.$t('isbn.only_digits')
          }

          if (this.isbn.trim().length !== 13) {
            return this.$t('isbn.invalid')
          }

          if (!this.isbn.trim().startsWith('97') && !this.isbn.trim().startsWith('99')) {
            return this.$t('isbn.invalid')
          }

          return true
        }
      ],
      referenceRules: [
        v => this.submitted || (!!v.trim() || !!this.isbn.trim() || this.$t('isbn.or_ref'))
      ],
      finitionRules: [v => v !== null || this.$t('form.required.product.finition')],
      categoryRules: [v => v !== null || this.$t('form.required.product.category')],
      acheveRules: [v => v !== null || this.$t('form.required.product.acheve')],
      versionRules: [
        v => v !== null || 'Version requise',
        v => v > 0 || 'Version supérieure à 0'],
      etatRules: [v => v !== null || 'Etat requis'],
      impressionRules: [v => v !== null || this.$t('form.required.product.impression')],
      formatRules: [() => this.enableFormat || this.format !== null || this.$t('form.required.product.format')],
      widthRules: [
        () => {
          if (!this.enableFormat) {
            return true
          }
          if (!this.width) {
            return this.$t('form.required.product.width')
          }

          if (!isNumeric('' + this.width || '')) {
            return this.$t('form.required.product.width')
          }


          // if (this.finition?.id === 1)
          if (parseFloat(this.width) < 100 || parseFloat(this.width) > 210)
            return this.$t('width_limit', [100, 210])

          // if (this.finition?.id === 2)
          //   if (parseFloat(this.width) < 85 || parseFloat(this.width) > 250)
          //     return this.$t('width_limit', [85, 250])

          return true
        }
      ],
      heightRules: [
        () => {
          if (!this.enableFormat) {
            return true
          }

          if (!this.height) {
            return this.$t('form.required.product.height')
          }

          if (!isNumeric('' + this.height || '')) {
            return this.$t('form.required.product.height')
          }

          // if (this.finition?.id === 1)
          if (parseFloat(this.height) < 160 || parseFloat(this.height) > 297)
            return this.$t('height_limit', [160, 297])

          // if (this.finition?.id === 2)
          //   if (parseFloat(this.height) < 148 || parseFloat(this.height) > 356)
          //     return this.$t('height_limit', [148, 356])

          return true
        }
      ],
      papierCouvertureRules: [
        v => v !== null || this.$t('form.required.product.paper.cover'),
        v => (v ? v.papier.impression != null ? v.papier.impression.id === this.impression.id : true : true) || this.$t('form.invalid.product.paper.cover')
      ],
      pelliculageRules: [v => v !== null || this.$t('form.required.product.lamination')],
      rectoVersoRules: [],
      pdfCouvertureRules: [],
      papierInterieurRules: [
        v => v !== null || this.$t('form.required.product.paper.inner'),
        v => (v ? v.papier.impression != null ? v.papier.impression.id === this.impression.id : true : true) || this.$t('form.invalid.product.paper.inner')
      ],
      bordsPerdusRules: [],
      pdfInterieurRules: [],
      pdfIntError: false,
      premierOngletRules: [() => {
        return !this.submitted ||
          [
            this.titreRules,
            this.auteurRules,
            this.isbnRules,
            this.referenceRules,
            this.finitionRules,
            this.categoryRules,
            this.acheveRules
          ].every(r => r.every(v => v() === true))
      }],
      secondOngletRules: [() => {
        return !this.submitted ||
          [
            this.impressionRules,
            this.formatRules,
            this.widthRules,
            this.heightRules
          ].every(r => r.every(v => v() === true))
      }],
      troisiemeOngletRules: [() => {
        return !this.submitted ||
          ([
            this.papierInterieurRules,
            this.bordsPerdusRules,
            this.pdfInterieurRules
          ].every(r => r.every(v => v() === true)) &&
            !this.pdfIntError &&
            this.isIntCouvMatch)
      }],
      quatriemeOngletRules: [() => {
        return !this.submitted ||
          ([
            this.papierCouvertureRules,
            this.pdfCouvertureRules,
            this.pelliculageRules,
            this.rectoVersoRules
          ].every(r => r.every(v => v() === true)) &&
            !this.pdfCouvError &&
            this.isIntCouvMatch)
      }]
    }
  },
  computed: {
    dos() {
      if (!(this.pdfIntPageCount > 0 && this.papier_couverture !== null && this.papier_interieur !== null)) return 0
      return Math.round(
        (((this.pdfIntPageCount / 2) * this.papier_interieur.papier.epaisseur + this.papier_couverture.papier.epaisseur * 2) / 1000) * 2
      ) / 2
    },
    isIntCouvMatch() {
      if (!this.pdfIntFormat && !this.pdfCouvFormat) return true
      if (this.finition?.id === 2 && !this.pdf_couverture) return true
      if (!this.pdf_interieur || !this.pdf_couverture) return true
      return Math.abs(this.pdfIntFormat.width - Math.round((this.pdfCouvFormat.width - this.dos) / 2)) <= 2 &&
        Math.abs(this.pdfIntFormat.height - this.pdfCouvFormat.height) <= 2
    },
    impression_items_filtered() {
      return this.impression_items.filter((i) => {
        return (this.finition?.id === 2 && i.id === 2) || this.finition?.id === 1
      })
    }
  },
  watch: {
    pdf_couverture: function () {
      this.checkPDFCouverture()
    },
    pdf_interieur: function () {
      this.getPDFFormat()
    },
    papier_couverture: function () {
      if(this.papier_couverture?.papier.is_creation){
        this.pelliculage = this.pelliculage_items[this.pelliculage_items.length - 1]
      }
    },
    finition: function () {
      if (this.finition?.id === 2) {
        this.acheve = this.acheve_items[0]
        this.impression = this.impression_items_filtered[0]
        this.bords_perdus = true
      }
    },
    impression: function () {
      this.updateImpression()
    }
  },
  methods: {
    checkPDFCouverture() {
      this.pdfCouvCount = 0
      this.pdfCouvFormat = null
      this.pdfCouvBleedbox = null
      this.pdfCouvError = false
      if (this.pdf_couverture !== null) {
        this.loadingCouv = true
        this.pdf_couverture.arrayBuffer().then(result => {
          PDFDocument.load(result)
            .then(doc => {
              this.pdfCouvCount = doc.getPageCount()
              const firstPage = doc.getPage(0)
              const trimbox = firstPage.getTrimBox()
              trimbox.width = Math.round(trimbox.width * (25.4 / 72))
              trimbox.height = Math.round(trimbox.height * (25.4 / 72))
              this.pdfCouvFormat = trimbox
              const bleedbox = firstPage.getBleedBox()
              bleedbox.width = Math.round(bleedbox.width * (25.4 / 72))
              bleedbox.height = Math.round(bleedbox.height * (25.4 / 72))
              this.pdfCouvBleedbox = bleedbox
              this.pdfCouvError = false
            })
            .catch(() => {
              this.pdfCouvCount = 0
              this.pdfCouvFormat = null
              this.pdfCouvBleedbox = null
              this.pdfCouvError = true
            })
            .finally(() => {
              this.loadingCouv = false
            })
        })
      }
    },
    getPDFFormat() {
      this.pdfIntFormat = null
      this.pdfIntPageCount = 0
      this.bords_perdus_dialog = false
      this.pdf_interieur_url = null
      this.pdfIntError = false
      if (this.pdf_interieur !== null) {
        this.loadingInt = true
        this.pdf_interieur.arrayBuffer().then(result => {
          PDFDocument.load(result).then(doc => {
            this.pdfIntPageCount = doc.getPageCount()
            const firstPage = doc.getPage(0)
            const trimbox = firstPage.getTrimBox()
            trimbox.width = Math.round(trimbox.width * (25.4 / 72))
            trimbox.height = Math.round(trimbox.height * (25.4 / 72))
            this.pdfIntFormat = trimbox

            const pdfIntBleedbox = firstPage.getBleedBox()
            pdfIntBleedbox.width = Math.round(pdfIntBleedbox.width * (25.4 / 72))
            pdfIntBleedbox.height = Math.round(pdfIntBleedbox.height * (25.4 / 72))

            if (pdfIntBleedbox && this.pdfIntFormat) {
              if (pdfIntBleedbox.width > this.pdfIntFormat.width || pdfIntBleedbox.height > this.pdfIntFormat.height) {
                this.bords_perdus_dialog = true
              }
            }
            this.pdfIntError = false
          })
            .catch(() => {
              this.pdfIntPageCount = 0
              this.pdfIntFormat = null
              this.pdfIntError = true
            })
            .finally(() => {
              this.loadingInt = false
            })
        })
      }
    },
    readPDFCouverture(e) {
      e.preventDefault()
      try {
        this.pdf_couverture = e.dataTransfer.files[0]
      } catch (e) {
        // pass

      }
    },
    readPDFInterieur(e) {
      e.preventDefault()
      try {
        this.pdf_interieur = e.dataTransfer.files[0]
      } catch (e) {
        // pass
      }
    },
    setDetectedFormat() {
      if (this.pdfIntFormat) {
        this.enableFormat = true
        this.format = null
        this.width = this.pdfIntFormat.width
        this.height = this.pdfIntFormat.height
      }
    },
    toggleDialog() {
      this.dialog = !this.dialog
      if (this.dialog && !this.opened) {
        this.getImpressions()
        this.getFinitions()
        this.getAcheves()
        this.getCategory()
        this.getFormats()
        this.getPelliculage()
        this.getPapierCouverture()
        this.getPapierInterieur()
        this.getEtats()
        this.opened = true
      }
    },
    getEtats() {
      axios
        .get(process.env.VUE_APP_API_URL + '/etat', {
          headers: {
            Authorization: `Bearer ${this.$store.getters.getJwtToken}`
          }
        })
        .then(response => {
          this.etat_items = response.data.etats
        })
    },
    getFinitions() {
      axios
        .get(
          process.env.VUE_APP_API_URL + '/finition',
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.getJwtToken}`
            }
          })
        .then((r) => {
          this.finition_items = r.data.finitions
          // this.finition = this.finition_items[0]
        })
        .catch(() => {
        })
    },
    getAcheves() {
      axios
        .get(
          process.env.VUE_APP_API_URL + '/acheve',
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.getJwtToken}`
            }
          })
        .then((r) => {
          this.acheve_items = r.data.acheves
        })
        .catch(() => {
        })
    },
    getCategory() {
      axios
        .get(
          process.env.VUE_APP_API_URL + '/category',
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.getJwtToken}`
            }
          })
        .then((r) => {
          this.category_items = r.data.categories
        })
        .catch(() => {
        })
    },
    getImpressions() {
      axios
        .get(
          process.env.VUE_APP_API_URL + '/impression',
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.getJwtToken}`
            }
          })
        .then((r) => {
          this.impression_items = r.data.impressions
        })
        .catch(() => {
        })
        .finally(() => {
          this.updateImpression()
        })
    },
    updateImpression() {
      if (this.impression === null) {
        this.papier_couverture_items = []
        this.papier_couverture = { id: 0, name: this.$t('choose_impression') }

        this.papier_interieur_items = []
        this.papier_interieur = { id: 0, name: this.$t('choose_impression') }
        return false
      }

      this.papier_couverture_items = this.papier_couverture_items_full.filter((p) => {
        return p.papier.impression === null || p.papier.impression.id === this.impression.id
      })
      this.papier_interieur_items = this.papier_interieur_items_full.filter((p) => {
        return p.papier.impression === null || p.papier.impression.id === this.impression.id
      })
    },
    getFormats() {
      axios
        .get(
          process.env.VUE_APP_API_URL + '/admin/formats',
          {
            params: {
              user_id: this.product.user.id
            },
            headers: {
              Authorization: `Bearer ${this.$store.getters.getJwtToken}`
            }
          })
        .then((r) => {
          this.format_items = r.data.items
        })
        .catch(() => {
        })
    },
    updateFormat() {
      if (this.enableFormat) {
        this.format = null
      } else {
        this.width = null
        this.height = null
      }
    },
    getPelliculage() {
      axios
        .get(
          process.env.VUE_APP_API_URL + '/pelliculage',
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.getJwtToken}`
            }
          })
        .then((r) => {
          this.pelliculage_items = r.data.pelliculages
        })
        .catch(() => {
        })
    },
    getPapierCouverture() {
      axios
        .get(
          process.env.VUE_APP_API_URL + '/papier_couverture',
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.getJwtToken}`
            }
          })
        .then((r) => {
          this.papier_couverture_items_full = r.data.papiers_couverture
        })
        .catch(() => {
        })
        .finally(() => {
          this.updateImpression()
        })
    },
    getPapierInterieur() {
      axios
        .get(
          process.env.VUE_APP_API_URL + '/papier_interieur',
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.getJwtToken}`
            }
          })
        .then((r) => {
          this.papier_interieur_items_full = r.data.papiers_interieur
        })
        .catch(() => {
        })
        .finally(() => {
          this.updateImpression()
        })
    },
    createProduct(e) {
      e.preventDefault()
      this.loading = true
      this.submitted = true
      this.progress = 0
      this.errors = null
      if (
        !this.$refs.formProduct.validate() ||
        this.pdfCouvError ||
        this.pdfIntError ||
        !this.isIntCouvMatch
      ) {
        this.loading = false
        return
      }
      const formData = new FormData()
      formData.append('title', this.titre ? this.titre.trim() : this.titre)
      formData.append('author', this.auteur ? this.auteur.trim() : this.auteur)
      formData.append('isbn', this.isbn ? this.isbn.trim() : this.isbn)
      formData.append('reference', this.reference ? this.reference.trim() : this.reference)
      formData.append('finition_id', this.finition.id)
      formData.append('category_id', this.category.id)
      formData.append('acheve_id', this.acheve.id)
      formData.append('version', this.version)

      formData.append('etat_id', this.f1.id)
      formData.append('f2', this.f2 ? 1 : 0)
      if (this.enabled) {
        formData.append('enabled', this.enabled)
      }
      formData.append('impression_id', this.impression.id)
      formData.append('papier_couverture_id', this.papier_couverture.id)
      formData.append('pelliculage_id', this.pelliculage.id)
      formData.append('papier_interieur_id', this.papier_interieur.id)
      if (this.bords_perdus) {
        formData.append('is_bords_perdus', this.bords_perdus)
      }
      if (this.recto_verso) {
        formData.append('is_couv_recto_verso', this.recto_verso)
      }
      if (this.pdf_interieur) {
        formData.append('pdf_interieur', this.pdf_interieur, this.pdf_interieur.name)
      }
      if (this.pdf_couverture) {
        formData.append('pdf_couverture', this.pdf_couverture, this.pdf_couverture.name)
      }
      if (!this.pdf_couverture && this.product.finition?.id === 2 && this.delete_couverture) {
        formData.append('delete_couverture', this.delete_couverture)
      }
      formData.append('comment', this.comment)

      if (!this.enableFormat) {
        formData.append('format_id', this.format.id)
      } else if (this.width !== null && this.height !== null) {
        formData.append('width', this.width)
        formData.append('height', this.height)
      }
      axios
        .put(
          process.env.VUE_APP_API_URL + '/admin/products/' + this.product.id,
          formData,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.getJwtToken}`
            },
            onUploadProgress: (progressEvent) => {
              this.progress = Math.round((progressEvent.loaded * 100) / progressEvent.total)
            }
          })
        .then(() => {
          this.submitted = false
          this.dialog = false
          this.loading = false
          this.progress = 0
          EventBus.$emit('notif', this.$t('edited'), 'success')
          EventBus.$emit('refreshproducts')
        })
        .catch((e) => {
          EventBus.$emit('notif', this.$t('global.error'), 'error')
          this.loading = false
          this.errors = e.response.data.errors
          this.$refs.formProduct.validate()
          this.progress = 0
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.add-book {
  .add-book-text {
    min-height: 50vh;
  }

  border-style: dashed !important;
  border-width: 1px !important;
  border-color: black !important;
}
</style>

<i18n lang="json">
{
  "fr": {
    "detect": {
      "inner": {
        "success": "Le format détecté du pdf (par rapport à la zone de rogne) correspond au format choisi -> format choisi : {0}mm - {1}mm / format détecté : {2}mm - {3}mm",
        "error": "Le format détecté du pdf (par rapport à la zone de rogne) ne correspond pas au format choisi -> format choisi : {0}mm - {1}mm / format détecté : {2}mm - {3}mm"
      },
      "inner_cover": {
        "match": "Les formats intérieur et couverture correspondent",
        "not_match": "Les formats intérieur et couverture ne correspondent pas : {0}x{1}mm intérieur et {2}x{3}mm couverture (Les formats pourraient correspondre si le dos est de {4} mm ou si le format de couverture est de {5}x{6}mm, vous pouvez choisir un autre papier intérieur ou couverture ou vous pouvez corriger vos fichiers)"
      },
      "cover_bleed": {
        "success": "La zone de rogne ({0}x{1}mm) de couverture est correcte par rapport à la zone de fond perdus ({2}x{3}mm)",
        "error": "La zone de rogne ({0}x{1}mm) de couverture est plus grande que la zone de fond perdus ({2}x{3}mm) de couverture"
      },
      "cover": {
        "success": "Votre pdf de couverture contient <strong>{0} page</strong>",
        "warning": "Traitement du pdf pour comptage des pages (si le traitement est dure dans le temps, il se peut que votre fichier est trop lourd et qu'il comporte plus de 2 pages)",
        "error": {
          "invalid": "Une erreur s'est produite lors de la détection du format et le nombre de pages, le fichier est peut-être invalide",
          "more": "Votre pdf de couverture contient plus de 2 pages (<strong>{0} pages</strong>), veuillez vérifier votre pdf",
          "two": "Attention votre pdf de couverture contient plus d'une page (<strong>{0} pages</strong>), vérifiez que cette information est correcte avant de valider le formulaire"
        }
      }
    },
    "fix": "Corriger",
    "tooltip_fix": "Ceci va modifier le format choisi sur la page 2 par rapport au format détecté",
    "please_select_format": "Veuillez sélectionner un format dans la rubrique précédente",
    "please_upload_inner": "Veuillez renseigner votre PDF intérieur et les types de papiers (intérieur et couverture) afin de calculer le dos",
    "calc_thickness": "Le dos sera de {0} mm",
    "detect_processing": "Traitement du pdf pour la détection du format",
    "processing": "Traitement",
    "field": "Champs",
    "expected": "Attendu",
    "detected": "Détecté",
    "errors": "Erreurs",
    "inner_format": "Format intérieur",
    "cover_format": "Format couverture",
    "only_server_side": "seulement calculé côté serveur",
    "isbn": {
      "or_ref": "Entrez soit une référence soit un isbn valide",
      "only_digits": "Entrez uniquement des chiffres",
      "invalid_13": "ISBN invalide (13 chiffres)",
      "invalid": "ISBN invalide"
    },
    "width_limit": "La largeur doit être comprise entre {0} et {1} mm",
    "height_limit": "La hauteur doit être comprise entre {0} et {1} mm",
    "choose_impression": "Choisissez un mode d'impression",
    "edited": "Produit Modifié",
    "created": "Produit Créé",
    "papier_creation": "Pas de pelliculage pour un papier de création"
  },
  "en": {
    "detect": {
      "inner": {
        "success": "The detected format of the PDF (compared to the bleed box) matches the chosen format -> chosen format: {0}mm - {1}mm / detected format: {2}mm - {3}mm",
        "error": "The detected format of the PDF (compared to the bleed box) does not match the chosen format -> chosen format: {0}mm - {1}mm / detected format: {2}mm - {3}mm"
      },
      "inner_cover": {
        "match": "The inner and cover formats match",
        "not_match": "The inner and cover formats do not match: {0}x{1}mm inner and {2}x{3}mm cover (The formats could match if the spine is {4} mm or if the cover format is {5}x{6}mm, you can choose a different inner or cover paper or you can correct your files)"
      },
      "cover_bleed": {
        "success": "The cover bleed box ({0}x{1}mm) is correct compared to the background bleed box ({2}x{3}mm)",
        "error": "The cover bleed box ({0}x{1}mm) is larger than the background bleed box ({2}x{3}mm)"
      },
      "cover": {
        "success": "Your cover PDF contains <strong>{0} page</strong>",
        "warning": "Processing the PDF to count the pages (if the processing takes a long time, your file may be too large and have more than 2 pages)",
        "error": {
          "invalid": "An error occurred while detecting the format and the number of pages, the file may be invalid",
          "more": "Your cover PDF contains more than 2 pages (<strong>{0} pages</strong>), please check your PDF",
          "two": "Attention, your cover PDF contains more than one page (<strong>{0} pages</strong>), please verify that this information is correct before submitting the form"
        }
      }
    },
    "fix": "Fix",
    "tooltip_fix": "This will adjust the chosen format on page 2 based on the detected format",
    "please_select_format": "Please select a format in the previous section",
    "please_upload_inner": "Please provide your inner PDF and paper types (inner and cover) to calculate the spine",
    "calc_thickness": "The spine will be {0} mm",
    "detect_processing": "Processing the PDF to detect the format",
    "processing": "Processing",
    "field": "Field",
    "expected": "Expected",
    "detected": "Detected",
    "errors": "Errors",
    "inner_format": "Inner Format",
    "cover_format": "Cover Format",
    "only_server_side": "only calculated server-side",
    "isbn": {
      "or_ref": "Enter either a reference or a valid ISBN",
      "only_digits": "Enter only digits",
      "invalid_13": "Invalid ISBN (13 digits)",
      "invalid": "Invalid ISBN"
    },
    "width_limit": "The width must be between {0} and {1} mm",
    "height_limit": "The height must be between {0} and {1} mm",
    "choose_impression": "Choose a printing mode",
    "edited": "Product Modified",
    "created": "Product Created",
    "papier_creation": "No pelliculage for a creation paper"
  }
}
</i18n>
