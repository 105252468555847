<template>
  <section class="admin">
    <v-tabs
      v-model="tab"
      show-arrows
      :vertical="$vuetify.breakpoint.lgAndUp"
      :centered="$vuetify.breakpoint.mdAndDown"
    >
      <v-tab
        v-for="(item, i) in items.filter((i) => i.condition)"
        :key="i"
        class="justify-start"
      >
        <v-icon left>{{ item.icon }}</v-icon>
        {{ item.name }}
      </v-tab>
      <v-tabs-items
        v-model="tab"
        touchless
        :vertical="$vuetify.breakpoint.lgAndUp"
      >
        <v-tab-item>
          <AccueilAdmin></AccueilAdmin>
        </v-tab-item>
        <v-tab-item>
          <ViewAdmin
            item-type="users"
            :headers="[
              { text: 'Action', value: 'action' },
              { text: 'Activé/Désactivé', value: 'enabled' },
              { text: 'Id', align: 'start', value: 'id' },
              { text: 'Pseudo', value: 'username' },
              { text: 'Nom', value: 'real_name' },
              { text: 'Email', value: 'email' },
              { text: 'Téléphone', value: 'phone' },
              { text: 'Code Compta', value: 'code_compta' },
              { text: 'Code Masterprint', value: 'code_masterprint' },
              { text: 'Crée le', value: 'created_at' },
              { text: 'Dernière connexion', value: 'last_login' },
              { text: 'Dernière activité', value: 'last_activity' },
              { text: 'Parent', value: 'user_parent.username' },
              { text: 'Roles', value: 'roles' },
            ]"
            :per-page="50"
            sort-by="last_activity"
          />
        </v-tab-item>
        <v-tab-item>
          <ViewAdminServerSide
            item-type="products"
            :headers="[
              { text: 'Action', value: 'action' },
              { text: 'Activé/Désactivé', value: 'enabled' },
              { text: 'Id', align: 'start', value: 'id' },
              { text: 'Uid', value: 'uid' },
              { text: 'ISBN', value: 'isbn' },
              { text: 'Référence', value: 'reference' },
              { text: 'Catégorie', value: 'category.name' },
              { text: 'Titre', value: 'title' },
              { text: 'Auteur', value: 'author' },
              { text: 'Nombre de pages', value: 'page_count' },
              { text: 'Epaisseur', value: 'epaisseur' },
              { text: 'Poids', value: 'weight' },
              { text: 'Format', value: 'format.name' },
              { text: 'Impression', value: 'impression.name' },
              { text: 'Pelliculage', value: 'pelliculage.name' },
              {
                text: 'Papier couverture',
                value: 'papier_couverture.papier.name',
              },
              {
                text: 'Papier interieur',
                value: 'papier_interieur.papier.name',
              },
              { text: 'Etat', value: 'f1.name' },
              { text: 'Envoyé au serveur', value: 'f2' },
              { text: 'Acheve', value: 'acheve.name' },
              { text: 'Finition', value: 'finition.name' },
              { text: 'Version', value: 'version' },
              { text: 'Mis à jour le', value: 'updated_at' },
              { text: 'Crée le', value: 'created_at' },
              { text: 'Utilisateur', value: 'user' },
            ]"
            :per-page="50"
          />
        </v-tab-item>
        <v-tab-item>
          <ViewAdminServerSide
            item-type="orders"
            :headers="[
              { text: 'Action', value: 'action' },
              { text: 'Activé/Désactivé', value: 'enabled' },
              { text: 'Id', align: 'start', value: 'id' },
              { text: 'Uid', align: 'start', value: 'uid' },
              { text: 'Etat', value: 'f1.name' },
              { text: 'Prix Fabrication', value: 'prix_fabrication' },
              { text: 'Prix Livraison', value: 'prix_livraison' },
              { text: 'Prix Emballage', value: 'prix_emballage' },
              { text: 'Prix HT', value: 'prix_ht' },
              { text: 'Prix TTC', value: 'prix_ttc' },
              { text: 'Crée le', value: 'created_at' },
              { text: 'Mis à jour le', value: 'updated_at' },
              { text: 'Utilisateur', value: 'user' },
              { text: 'Lignes de commande', value: 'lignes_commande' },
            ]"
            :per-page="50"
          />
        </v-tab-item>
        <v-tab-item>
          <ViewAdminServerSide
            item-type="lines"
            :headers="[
              { text: 'Action', value: 'action' },
              { text: 'Activé/Désactivé', value: 'enabled' },
              { text: 'Id', align: 'start', value: 'id' },
              { text: 'Produit', value: 'product' },
              { text: 'Commande', value: 'commande.uid' },
              { text: 'Etat', value: 'f1.name' },
              { text: 'Quantité', value: 'quantity' },
              { text: 'Commentaire', value: 'comment' },
              { text: 'Crée le', value: 'created_at' },
              { text: 'Mis à jour le', value: 'updated_at' },
            ]"
            :per-page="50"
          />
        </v-tab-item>
        <v-tab-item>
          <ViewAdminServerSide
            item-type="addresses"
            :headers="[
              { text: 'Action', value: 'action' },
              { text: 'Activé/Désactivé', value: 'enabled' },
              { text: 'Id', align: 'start', value: 'id' },
              { text: 'Adresse', value: 'address' },
              { text: 'Complément', value: 'complement' },
              { text: 'Ville', value: 'city' },
              { text: 'Code Postal', value: 'code_postal' },
              { text: 'Pays', value: 'country.name' },
              { text: 'Contact', value: 'contact' },
              { text: 'Raison Sociale', value: 'raison_sociale' },
              { text: 'Email', value: 'email' },
              { text: 'Fixe', value: 'phone_fix' },
              { text: 'Mobile', value: 'phone_mobile' },
              { text: 'Type', value: 'address_type.name' },
              { text: 'Commentaire', value: 'comment' },
              { text: 'Utilisateur', value: 'user' },
              { text: 'Crée le', value: 'created_at' },
              { text: 'Mis à jour le', value: 'updated_at' },
            ]"
            :per-page="50"
          />
        </v-tab-item>
        <v-tab-item>
          <ViewAdmin
            item-type="formats"
            :headers="[
              { text: 'Action', value: 'action' },
              { text: 'Activé/Désactivé', value: 'enabled' },
              { text: 'Id', align: 'start', value: 'id' },
              { text: 'Libellé', value: 'name' },
              { text: 'Hauteur', value: 'height' },
              { text: 'Largeur', value: 'width' },
              { text: 'Utilisateur', value: 'user' },
              { text: 'Crée le', value: 'created_at' },
              { text: 'Mis à jour le', value: 'updated_at' },
            ]"
            :per-page="50"
          />
        </v-tab-item>
        <v-tab-item>
          <ViewAdmin
            item-type="finitions"
            :headers="[
              { text: 'Action', value: 'action' },
              { text: 'Activé/Désactivé', value: 'enabled' },
              { text: 'Id', align: 'start', value: 'id' },
              { text: 'Libellé', value: 'name' },
              { text: 'Libellé Anglais', value: 'en_name' },
              {
                text: 'Hauteur minimum intérieure',
                value: 'min_height_interieur',
              },
              {
                text: 'Hauteur maximum intérieure',
                value: 'max_height_interieur',
              },
              {
                text: 'Hauteur minimum couverture',
                value: 'min_height_couverture',
              },
              {
                text: 'Hauteur maximum couverture',
                value: 'max_height_couverture',
              },
              {
                text: 'Largeur minimum intérieure',
                value: 'min_width_interieur',
              },
              {
                text: 'Largeur maximum intérieure',
                value: 'max_width_interieur',
              },
              {
                text: 'Largeur minimum couverture',
                value: 'min_width_couverture',
              },
              {
                text: 'Largeur maximum couverture',
                value: 'max_width_couverture',
              },
              { text: 'Nombre de pages minimum', value: 'min_page_count' },
              { text: 'Nombre de pages maximum', value: 'max_page_count' },
              { text: 'Quantité minimum', value: 'min_quantity' },
              { text: 'Quantité maximum', value: 'max_quantity' },
              { text: 'Epaisseur minimum', value: 'min_epaisseur' },
              { text: 'Epaisseur maximum', value: 'max_epaisseur' },
              { text: 'Bords perdus minimum', value: 'min_bords_perdus' },
              { text: 'Bords perdus maximum', value: 'max_bords_perdus' },
              {
                text: 'Pourcentage minimum bords perdus',
                value: 'max_pourcentage_bords_perdus',
              },
              { text: 'Crée le', value: 'created_at' },
              { text: 'Mis à jour le', value: 'updated_at' },
            ]"
            :per-page="50"
          />
        </v-tab-item>
        <v-tab-item>
          <ViewAdmin
            item-type="impressions"
            :headers="[
              { text: 'Action', value: 'action' },
              { text: 'Activé/Désactivé', value: 'enabled' },
              { text: 'Id', align: 'start', value: 'id' },
              { text: 'Libellé', value: 'name' },
              { text: 'Libellé Anglais', value: 'en_name' },
              { text: 'Nombre de pages minimum', value: 'min_page_count' },
              { text: 'Nombre de pages maximum', value: 'max_page_count' },
              { text: 'Crée le', value: 'created_at' },
              { text: 'Mis à jour le', value: 'updated_at' },
            ]"
            :per-page="50"
          />
        </v-tab-item>
        <v-tab-item>
          <ViewAdmin
            item-type="pelliculages"
            :headers="[
              { text: 'Action', value: 'action' },
              { text: 'Activé/Désactivé', value: 'enabled' },
              { text: 'Id', align: 'start', value: 'id' },
              { text: 'Libellé', value: 'name' },
              { text: 'Libellé Anglais', value: 'en_name' },
              { text: 'Crée le', value: 'created_at' },
              { text: 'Mis à jour le', value: 'updated_at' },
            ]"
            :per-page="50"
          />
        </v-tab-item>
        <v-tab-item>
          <ViewAdmin
            item-type="papiersinterieur"
            :headers="[
              { text: 'Action', value: 'action' },
              { text: 'Activé/Désactivé', value: 'enabled' },
              { text: 'Papier Création', value: 'is_creation' },
              { text: 'Id', align: 'start', value: 'id' },
              { text: 'Libellé', value: 'name' },
              { text: 'Libellé Anglais', value: 'en_name' },
              { text: 'Libellé Fichier', value: 'file_name' },
              { text: 'Poids', value: 'weight' },
              { text: 'Epaisseur', value: 'epaisseur' },
              { text: 'Prix', value: 'price' },
              { text: 'Impression', value: 'impression.name' },
              { text: 'Min Exemplaires', value: 'min_exemplaires' },
              {
                text: 'Min Exemplaires Couleur',
                value: 'min_exemplaires_couleur',
              },
              {
                text: 'Max Exemplaires',
                value: 'max_exemplaires',
              },
              {
                text: 'Max Exemplaires Couleur',
                value: 'max_exemplaires_couleur',
              },
              { text: 'Crée le', value: 'created_at' },
              { text: 'Mis à jour le', value: 'updated_at' },
            ]"
            :per-page="50"
          />
        </v-tab-item>
        <v-tab-item>
          <ViewAdmin
            item-type="papierscouverture"
            :headers="[
              { text: 'Action', value: 'action' },
              { text: 'Activé/Désactivé', value: 'enabled' },
              { text: 'Papier Création', value: 'is_creation' },
              { text: 'Id', align: 'start', value: 'id' },
              { text: 'Libellé', value: 'name' },
              { text: 'Libellé Anglais', value: 'en_name' },
              { text: 'Libellé Fichier', value: 'file_name' },
              { text: 'Poids', value: 'weight' },
              { text: 'Epaisseur', value: 'epaisseur' },
              { text: 'Prix', value: 'price' },
              { text: 'Impression', value: 'impression.name' },
              { text: 'Min Exemplaires', value: 'min_exemplaires' },
              {
                text: 'Min Exemplaires Couleur',
                value: 'min_exemplaires_couleur',
              },
              {
                text: 'Max Exemplaires',
                value: 'max_exemplaires',
              },
              {
                text: 'Max Exemplaires Couleur',
                value: 'max_exemplaires_couleur',
              },
              { text: 'Crée le', value: 'created_at' },
              { text: 'Mis à jour le', value: 'updated_at' },
            ]"
            :per-page="50"
          />
        </v-tab-item>
        <v-tab-item>
          <ViewAdmin
            item-type="etats"
            :headers="[
              { text: 'Action', value: 'action' },
              { text: 'Activé/Désactivé', value: 'enabled' },
              { text: 'Id', align: 'start', value: 'id' },
              { text: 'Libellé', value: 'name' },
              { text: 'Libellé Anglais', value: 'en_name' },
              { text: 'Crée le', value: 'created_at' },
              { text: 'Mis à jour le', value: 'updated_at' },
            ]"
            :per-page="50"
          />
        </v-tab-item>
        <v-tab-item>
          <ViewAdmin
            item-type="etatscommande"
            :headers="[
              { text: 'Action', value: 'action' },
              { text: 'Activé/Désactivé', value: 'enabled' },
              { text: 'Id', align: 'start', value: 'id' },
              { text: 'Libellé', value: 'name' },
              { text: 'Libellé Anglais', value: 'en_name' },
              { text: 'Crée le', value: 'created_at' },
              { text: 'Mis à jour le', value: 'updated_at' },
            ]"
            :per-page="50"
          />
        </v-tab-item>
        <v-tab-item>
          <ViewAdmin
            item-type="etatslignecommande"
            :headers="[
              { text: 'Action', value: 'action' },
              { text: 'Activé/Désactivé', value: 'enabled' },
              { text: 'Id', align: 'start', value: 'id' },
              { text: 'Libellé', value: 'name' },
              { text: 'Libellé Anglais', value: 'en_name' },
              { text: 'Crée le', value: 'created_at' },
              { text: 'Mis à jour le', value: 'updated_at' },
            ]"
          />
        </v-tab-item>
        <v-tab-item>
          <ViewAdmin
            item-type="acheves"
            :headers="[
              { text: 'Action', value: 'action' },
              { text: 'Activé/Désactivé', value: 'enabled' },
              { text: 'Id', align: 'start', value: 'id' },
              { text: 'Libellé', value: 'name' },
              { text: 'Libellé Anglais', value: 'en_name' },
              { text: 'Crée le', value: 'created_at' },
              { text: 'Mis à jour le', value: 'updated_at' },
            ]"
          />
        </v-tab-item>
        <v-tab-item>
          <ViewAdmin
            item-type="categories"
            :headers="[
              { text: 'Action', value: 'action' },
              { text: 'Activé/Désactivé', value: 'enabled' },
              { text: 'Id', align: 'start', value: 'id' },
              { text: 'Libellé', value: 'name' },
              { text: 'Libellé Anglais', value: 'en_name' },
              { text: 'TVA', value: 'tva' },
              { text: 'Crée le', value: 'created_at' },
              { text: 'Mis à jour le', value: 'updated_at' },
            ]"
          />
        </v-tab-item>
        <v-tab-item>
          <ViewAdmin
            item-type="countries"
            :headers="[
              { text: 'Action', value: 'action' },
              { text: 'Activé/Désactivé', value: 'enabled' },
              { text: 'Id', align: 'start', value: 'id' },
              { text: 'Libellé', value: 'name' },
              { text: 'Libellé Anglais', value: 'en_name' },
              { text: 'ISO', value: 'iso' },
              { text: 'Export', value: 'is_export' },
              { text: 'Crée le', value: 'created_at' },
              { text: 'Mis à jour le', value: 'updated_at' },
            ]"
          />
        </v-tab-item>
        <v-tab-item>
          <ViewAdmin
            item-type="pending_cart"
            :headers="[
              { text: 'Action', value: 'action' },
              { text: 'Activé/Désactivé', value: 'enabled' },
              { text: 'Id', align: 'start', value: 'id' },
              { text: 'Uid', align: 'start', value: 'uid' },
              { text: 'Etat', value: 'f1.name' },
              { text: 'Prix Fabrication', value: 'prix_fabrication' },
              { text: 'Prix Livraison', value: 'prix_livraison' },
              { text: 'Prix Emballage', value: 'prix_emballage' },
              { text: 'Prix HT', value: 'prix_ht' },
              { text: 'Prix TTC', value: 'prix_ttc' },
              { text: 'Crée le', value: 'created_at' },
              { text: 'Mis à jour le', value: 'updated_at' },
              { text: 'Utilisateur', value: 'user' },
              { text: 'Lignes de commande', value: 'lignes_commande' },
            ]"
            :per-page="50"
          />
        </v-tab-item>
        <v-tab-item>
          <ViewAdminServerSide
            item-type="simulation_logs"
            :headers="[
              { text: 'Id', align: 'start', value: 'id' },
              { text: 'Ip', value: 'ip' },
              { text: 'Utilisateur', value: 'user' },
              { text: 'Données', value: 'data' },
              { text: 'Date', value: 'datetime' },
            ]"
            :per-page="500"
            sort-by="datetime"
          />
        </v-tab-item>
        <v-tab-item>
          <ViewAdminServerSide
            item-type="logs"
            :headers="[
              { text: 'Id', align: 'start', value: 'id' },
              { text: 'Ip', value: 'ip' },
              { text: 'Méthode', value: 'method' },
              { text: 'Route', value: 'route' },
              { text: 'Utilisateur', value: 'user' },
              { text: 'Date', value: 'datetime' },
            ]"
            :per-page="500"
            sort-by="datetime"
          />
        </v-tab-item>
        <v-tab-item>
          <ViewAdminServerSide
            item-type="otp_logs"
            :headers="[
              { text: 'Id', align: 'start', value: 'id' },
              { text: 'Code', value: 'code' },
              { text: 'Utilsateur', value: 'user' },
              { text: 'Utilisé', value: 'used' },
              { text: 'Crée le', value: 'created_at' },
            ]"
            :per-page="50"
            sort-by="created_at"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </section>
</template>

<script lang="js">
import ViewAdmin from '@/components/admin/ViewAdmin'
import ViewAdminServerSide from '@/components/admin/ViewAdminServerSide'
import AccueilAdmin from '@/components/admin/AccueilAdmin'

export default {
  name: 'Admin',
  metaInfo: {
    title: 'Rapidbook - Admin',
  },
  components: {
    AccueilAdmin,
    ViewAdmin,
    ViewAdminServerSide
  },
  props: [],
  data () {
    return {
      tab: null,
      items: [
        { name: 'Accueil', icon: 'mdi-home-outline', condition: this.$store.getters.isAdmin },
        { name: 'Utilisateurs', icon: 'mdi-account-group-outline', condition: this.$store.getters.isAdmin },
        { name: 'Produits', icon: 'mdi-book-multiple-outline', condition: this.$store.getters.isAdmin },
        { name: 'Commandes', icon: 'mdi-credit-card-multiple-outline', condition: this.$store.getters.isAdmin },
        { name: 'Lignes de commande', icon: 'mdi-format-list-bulleted', condition: this.$store.getters.isAdmin },
        { name: 'Adresses', icon: 'mdi-map-marker-multiple-outline', condition: this.$store.getters.isAdmin },
        { name: 'Formats', icon: 'mdi-format-size', condition: this.$store.getters.isAdmin },
        { name: 'Finitions', icon: 'mdi-notebook-outline', condition: this.$store.getters.isAdmin },
        { name: 'Impressions', icon: 'mdi-printer-outline', condition: this.$store.getters.isAdmin },
        { name: 'Pelliculages', icon: 'mdi-filmstrip-box-multiple', condition: this.$store.getters.isAdmin },
        { name: 'Papiers Interieur', icon: 'mdi-note-multiple-outline', condition: this.$store.getters.isAdmin },
        { name: 'Papiers Couverture', icon: 'mdi-note-multiple-outline', condition: this.$store.getters.isAdmin },
        { name: 'Etats Produit', icon: 'mdi-toggle-switch-off-outline', condition: this.$store.getters.isAdmin },
        { name: 'Etats Commande', icon: 'mdi-toggle-switch-off-outline', condition: this.$store.getters.isAdmin },
        { name: 'Etats Ligne Commande', icon: 'mdi-toggle-switch-off-outline', condition: this.$store.getters.isAdmin },
        { name: 'Achevés', icon: 'mdi-calendar-range', condition: this.$store.getters.isAdmin },
        { name: 'Catégories', icon: 'mdi-shape-outline', condition: this.$store.getters.isAdmin },
        { name: 'Pays', icon: 'mdi-map-marker-outline', condition: this.$store.getters.isAdmin },
        { name: 'Paniers non validés', icon: 'mdi-cart-outline', condition: this.$store.getters.isAdmin },
        { name: 'Simulations passées', icon: 'mdi-poll', condition: this.$store.getters.isAdmin },
        { name: 'Logs', icon: 'mdi-information-outline', condition: this.$store.getters.isAdmin },
        { name: 'Logs OTP', icon: 'mdi-lock-reset', condition: this.$store.getters.isAdmin }
      ]
    }
  }
}
</script>

<style scoped lang="scss">
.admin {
  min-height: calc(100vh - 64px);
}

.tile {
  transition: background-color 0.3s;
}

.tile:hover {
  background-color: var(--color-yellow);
}
</style>
