import { render, staticRenderFns } from "./Simulation.vue?vue&type=template&id=5affc7a8&scoped=true"
import script from "./Simulation.vue?vue&type=script&lang=js"
export * from "./Simulation.vue?vue&type=script&lang=js"
import style0 from "./Simulation.vue?vue&type=style&index=0&id=5affc7a8&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5affc7a8",
  null
  
)

/* custom blocks */
import block0 from "./Simulation.vue?vue&type=custom&index=0&blockType=i18n&lang=json"
if (typeof block0 === 'function') block0(component)

export default component.exports