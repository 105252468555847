import Vue from "vue";
import Vuex from "vuex";
import { EventBus, isValidJwt } from "@/utils";
import axios from "axios";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    jwt: Cookies.get("rapidbook-jwt") || "",
    locale: Cookies.get("rapidbook-locale") || process.env.VUE_APP_I18N_LOCALE,
  },
  mutations: {
    setJwtToken(state, payload) {
      state.jwt = payload.jwt;
      if (!["", undefined].includes(payload.jwt)) {
        Cookies.set("rapidbook-jwt", payload.jwt, {
          expires: new Date(jwtDecode(payload.jwt).exp * 1000),
          sameSite: "strict",
          secure: true,
        });
      }
    },
  },
  actions: {
    login(context, userData) {
      axios
        .post(`${process.env.VUE_APP_API_URL}/login`, userData)
        .then((response) => {
          context.commit("setJwtToken", { jwt: response.data.token });
          EventBus.$emit("successAuthentication");
        })
        .catch((error) => {
          if (error.response) {
            EventBus.$emit("failedAuthentication", error.response.data.message);
          } else {
            EventBus.$emit("failedAuthentication", "Server error");
          }
        });
    },
    register(context, userData) {
      return axios
        .post(`${process.env.VUE_APP_API_URL}/register`, userData)
        .then(context.dispatch("login", userData))
        .catch((error) => {
          EventBus.$emit("failedRegistering: ", error);
        });
    },
    logout(context) {
      context.commit("setJwtToken", "");
      Cookies.remove("rapidbook-jwt");
    },
    spoof(context, id) {
      axios
        .post(`${process.env.VUE_APP_API_URL}/admin/spoof/` + id, null, {
          headers: {
            Authorization: `Bearer ${context.getters.getJwtToken}`,
          },
        })
        .then((response) => {
          context.commit("setJwtToken", { jwt: response.data.token });
          EventBus.$emit("successAuthentication");
          EventBus.$emit("notif", "Changement de compte effectué", "success");
        })
        .catch((error) => {
          if (error.response) {
            EventBus.$emit("failedAuthentication", error.response.data.message);
          } else {
            EventBus.$emit("failedAuthentication", "Server error");
          }
        });
    },
    changeLocale(context, locale) {
      Cookies.set("rapidbook-locale", locale, {
        sameSite: "strict",
        secure: true,
      });
    },
  },
  modules: {},
  getters: {
    isAuthenticated(state, context) {
      try {
        isValidJwt(state.jwt) ? null : context.dispatch("logout");
        return isValidJwt(state.jwt);
      } catch (e) {
        return false;
      }
    },
    isAdmin(state) {
      try {
        return JSON.parse(atob(state.jwt.split(".")[1])).is_admin;
      } catch (e) {
        return false;
      }
    },
    isChild(state) {
      try {
        return JSON.parse(atob(state.jwt.split(".")[1])).is_child;
      } catch (e) {
        return false;
      }
    },
    getUsername(state) {
      try {
        return JSON.parse(atob(state.jwt.split(".")[1])).username;
      } catch (e) {
        return false;
      }
    },
    getParentUsername(state) {
      try {
        return JSON.parse(atob(state.jwt.split(".")[1])).parent_username;
      } catch (e) {
        return false;
      }
    },
    getJwtToken(state) {
      return state.jwt || "";
    },
    getLocale(state) {
      return state.locale;
    },
  },
});
