<template>
  <div class="font-weight-light">
    <section id="section-0" class="home">
      <v-parallax
        :height="fullHeight"
        src="@/assets/img/accueil/image_accueil.jpeg"
      >
        <v-row align="center">
          <v-col cols="0" md="2" lg="4" xl="6" order="1" order-md="0"> </v-col>
          <v-col cols="12" md="9" lg="8" xl="5" class="mr-auto">
            <v-card
              shaped
              class="produit-parallax rellax"
              elevation="0"
              data-rellax-percentage="0.5"
              data-rellax-speed="7"
            >
              <v-card-title
                :class="
                  'title-1 ' +
                  ($vuetify.breakpoint.smAndUp ? 'text-h4' : 'text-h5') +
                  ' mb-3'
                "
              >
                <strong>
                  <p v-html="$t('home-1')"></p>
                </strong>
              </v-card-title>
              <v-card-subtitle>
                <v-img
                  src="@/assets/img/accueil/filet_titre.svg"
                  width="200"
                  height="50"
                  contain
                />
              </v-card-subtitle>
              <v-card-subtitle class="text-h6">
                {{ $t("home-2") }}
              </v-card-subtitle>
              <v-card-subtitle
                v-if="$store.getters.isAuthenticated"
                align="center"
              >
                <v-btn
                  :to="{ name: 'Dashboard' }"
                  large
                  class="yellow--bg border-shaped mb-n8"
                  elevation="0"
                >
                  {{ $t("menu.dashboard") }}
                </v-btn>
              </v-card-subtitle>
              <v-card-subtitle v-else align="center">
                <v-btn
                  :to="{ name: 'Login' }"
                  large
                  class="yellow--bg border-shaped mb-n8"
                  elevation="0"
                >
                  {{ $t("menu.login") }}
                </v-btn>
              </v-card-subtitle>
            </v-card>
          </v-col>
        </v-row>
      </v-parallax>
    </section>
    <section id="section-1">
      <v-container fluid>
        <v-row>
          <v-col
            v-if="$vuetify.breakpoint.smAndUp"
            cols="1"
            md="2"
            class="d-flex bordure-gauche-container"
          >
            <img
              class="bordureGauche my-auto rellax"
              data-rellax-percentage="0.5"
              data-rellax-speed="7"
              src="~@/assets/img/home_one_page/01_arguments_marketing/element_bordure_gauche.svg"
            />
          </v-col>
          <v-col cols="11" md="8" class="mt-8 ml-sm-0 ml-5">
            <div class="row">
              <div class="title-1 text-h4">
                <strong>
                  <p v-html="$t('home-3')"></p>
                </strong>
                <img
                  src="~@/assets/img/home_one_page/01_arguments_marketing/element_soustitre.svg"
                  class="element-soustitre"
                />
              </div>
            </div>
            <v-row class="my-3">
              <div class="title-2 text-h6">
                <p>{{ $t("home-4") }}</p>
              </div>
            </v-row>
            <v-row>
              <v-col md="6" class="pr-lg-4 pl-0">
                <p class="text-justify" v-html="$t('home-5')"></p>
              </v-col>
              <v-col md="6" class="pl-lg-3 pl-0">
                <p class="text-justify" v-html="$t('home-6')"></p>
              </v-col>
            </v-row>
            <v-row class="pb-5">
              <v-col md="6" class="pr-lg-4 pl-0">
                <p class="text-justify" v-html="$t('home-7')"></p>
              </v-col>
              <div class="col-md-6 pl-lg-3 pl-0">
                <p v-html="$t('home-8')"></p>
              </div>
            </v-row>
            <v-row class="arguments text-center mb-5">
              <v-col md="3">
                <img
                  src="~@/assets/img/home_one_page/01_arguments_marketing/livraison.svg"
                />
                <p class="h5 mt-3 mb-3" v-html="$t('home-9')"></p>
                <p class="mb-5" v-html="$t('home-10')"></p>
              </v-col>
              <v-col md="6">
                <v-row>
                  <v-col md="2">
                    <img
                      class="separation"
                      src="~@/assets/img/home_one_page/01_arguments_marketing/element_separation_picto.svg"
                    />
                  </v-col>
                  <v-col md="8">
                    <img
                      src="~@/assets/img/home_one_page/01_arguments_marketing/un_exemplaire.svg"
                    />
                    <p class="h5 mt-3 mb-3" v-html="$t('home-11')"></p>
                    <p class="mb-5" v-html="$t('home-12')"></p>
                  </v-col>
                  <v-col md="2">
                    <img
                      class="separation"
                      src="~@/assets/img/home_one_page/01_arguments_marketing/element_separation_picto.svg"
                    />
                  </v-col>
                </v-row>
              </v-col>
              <v-col md="3">
                <img
                  src="~@/assets/img/home_one_page/01_arguments_marketing/prix_degressif.svg"
                />
                <p class="h5 mt-3 mb-3" v-html="$t('home-13')"></p>
                <p class="mb-5" v-html="$t('home-14')"></p>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="2"></v-col>
        </v-row>
      </v-container>
    </section>
    <section id="section-2">
      <img
        width="100%"
        src="~@/assets/img/home_one_page/02_histoire/element_haut.svg"
        contain
      />
      <v-container fluid class="mt-8">
        <v-row>
          <v-col cols="1" md="1" lg="2"></v-col>
          <v-col cols="10" md="4" lg="3">
            <v-row>
              <div class="title-1 text-h4">
                <p>
                  <a href="https://www.corlet.fr/"
                    ><img
                      src="~@/assets/img/logo-corlet-2021.png"
                      height="50"
                      class="element-logocorlet"
                  /></a>
                  <strong class="ml-2">{{ $t("home-15") }}</strong>
                </p>
                <img
                  src="~@/assets/img/home_one_page/01_arguments_marketing/element_soustitre.svg"
                  class="element-soustitre"
                />
              </div>
            </v-row>
            <v-row class="my-3">
              <div class="title-2 text-h6">
                <p v-html="$t('home-16')"></p>
              </div>
            </v-row>
            <v-row>
              <p class="text-justify" v-html="$t('home-17')"></p>
              <p class="text-justify" v-html="$t('home-18')"></p>
              <p class="text-justify" v-html="$t('home-19')"></p>
            </v-row>
          </v-col>
          <v-col cols="10" md="4" lg="4" class="ml-auto">
            <v-row class="my-3">
              <div class="title-2 text-h5">
                <strong>
                  <p v-html="$t('home-20')"></p>
                </strong>
                <img
                  src="~@/assets/img/home_one_page/02_histoire/element_haut.svg"
                  class="element-soustitre-raisons"
                />
              </div>
            </v-row>
            <v-row class="text-center raisons-choix">
              <v-col lg="6">
                <img
                  src="~@/assets/img/home_one_page/02_histoire/60ans_experience.svg"
                />
                <p class="mt-3 mb-3" v-html="$t('home-21')"></p>
                <p class="small mb-5" v-html="$t('home-22')"></p>
              </v-col>
              <v-col cols="6">
                <img
                  src="~@/assets/img/home_one_page/02_histoire/4_imprimeur.svg"
                />
                <p class="mt-3 mb-3" v-html="$t('home-23')"></p>
                <p class="small mb-5" v-html="$t('home-24')"></p>
              </v-col>
              <v-col cols="6">
                <img
                  src="~@/assets/img/home_one_page/02_histoire/collaborateur.svg"
                />
                <p class="mt-3 mb-3" v-html="$t('home-25')"></p>
                <p class="small mb-5" v-html="$t('home-26')"></p>
              </v-col>
              <v-col cols="6">
                <img
                  src="~@/assets/img/home_one_page/02_histoire/made_in_normandie.svg"
                />
                <p class="mt-3 mb-3" v-html="$t('home-27')"></p>
                <p class="small mb-5" v-html="$t('home-28')"></p>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="1" md="1" lg="2"></v-col>
        </v-row>
      </v-container>
    </section>
    <section id="section-3">
      <v-parallax
        :height="fullHeight"
        src="~@/assets/img/home_one_page/03_produit/image.jpeg"
      >
        <v-container fluid>
          <v-row class="produit_parallax_background" style="z-index: -1">
            <v-col cols="8"></v-col>
            <v-col cols="4" class="produit_parallax_background_white"></v-col>
          </v-row>
          <v-row class="produit-parallax-container py-5">
            <v-col
              cols="12"
              md="7"
              lg="6"
              xl="5"
              class="ml-auto my-auto p-sm-5 p-4 produit-parallax rellax"
              data-rellax-percentage="0.5"
              data-rellax-speed="7"
            >
              <v-row class="pl-2">
                <div
                  :class="
                    'title-1 ' +
                    ($vuetify.breakpoint.smAndUp ? 'text-h4' : 'text-h5')
                  "
                >
                  <strong>
                    <p v-html="$t('home-29')"></p>
                  </strong>
                  <img
                    src="~@/assets/img/home_one_page/01_arguments_marketing/element_soustitre.svg"
                    class="element-soustitre"
                  />
                </div>
              </v-row>
              <v-row class="my-3">
                <div class="title-2 text-h6 pl-2">
                  <p v-html="$t('home-30')"></p>
                </div>
              </v-row>
              <v-row class="mr-sm-4 black--text">
                <p class="text-justify pl-2" v-html="$t('home-31')"></p>
                <p class="text-justify pl-2" v-html="$t('home-32')"></p>
              </v-row>
            </v-col>
            <v-col lg="1" xl="1"></v-col>
          </v-row>
        </v-container>
      </v-parallax>
    </section>
    <section id="section-4">
      <v-container fluid>
        <v-row class="equipe_background" style="z-index: 0">
          <v-col cols="8"></v-col>
          <v-col
            cols="3"
            class="yellow--bg ml-4 d-none d-sm-block"
            :style="'height:' + fullHeight + 'px'"
          ></v-col>
        </v-row>
        <v-row class="produit-parallax-container py-5">
          <v-col cols="1" md="2" class="d-flex">
            <img
              class="bordureGauche my-auto rellax"
              data-rellax-percentage="0.5"
              data-rellax-speed="4"
              src="~@/assets/img/home_one_page/01_arguments_marketing/element_bordure_gauche.svg"
            />
          </v-col>
          <v-col
            cols="10"
            sm="9"
            md="4"
            class="pt-md-0 pt-5 my-auto ml-sm-0 ml-5"
            style="z-index: 1"
          >
            <v-row>
              <div class="title-1 text-h4">
                <strong>
                  <p v-html="$t('home-33')"></p>
                </strong>
                <img
                  src="~@/assets/img/home_one_page/04_equipe/element_soustitre.svg"
                  class="element-soustitre"
                />
              </div>
            </v-row>
            <v-row class="my-3">
              <div class="title-2 text-h6">
                <p v-html="$t('home-34')"></p>
              </div>
            </v-row>
            <v-row>
              <p class="text-justify" v-html="$t('home-35')"></p>
            </v-row>
            <div class="row d-flex justify-content-center">
              <v-btn class="yellow--bg border-shaped" to="/contact" depressed>
                {{ $t("menu.contact") }}
              </v-btn>
            </div>
          </v-col>
          <v-col
            cols="12"
            md="4"
            class="d-flex my-auto rellax"
            data-rellax-percentage="0.5"
            data-rellax-speed="7"
          >
            <v-img
              class="img-equipe mx-auto my-md-0 my-sm-5 my-5 ml-md-5 vanilla-tilt"
              src="~@/assets/img/home_one_page/04_equipe/photo_rapidbook.jpg"
              style="z-index: 1"
              contain
            />
          </v-col>
          <v-col lg="1"></v-col>
        </v-row>
      </v-container>
    </section>
    <section id="section-5">
      <v-parallax
        :height="fullHeight"
        src="~@/assets/img/home_one_page/05_ecoresponsable/image.jpeg"
      >
        <v-container fluid>
          <v-row class="produit_parallax_background" style="z-index: -1">
            <v-col cols="8"></v-col>
            <v-col cols="4" class="produit_parallax_background_white"></v-col>
          </v-row>
          <v-row class="produit-parallax-container py-5">
            <v-col
              cols="12"
              md="7"
              lg="6"
              xl="5"
              class="ml-auto my-auto p-sm-5 p-4 produit-parallax rellax"
              data-rellax-percentage="0.5"
              data-rellax-speed="7"
            >
              <v-row>
                <div class="title-1 text-h4 pl-2">
                  <strong>
                    <p v-html="$t('home-36')"></p>
                  </strong>
                  <img
                    src="~@/assets/img/home_one_page/05_ecoresponsable/element_soustitre.svg"
                    class="element-soustitre"
                  />
                </div>
              </v-row>
              <v-row class="my-3 pl-2">
                <div class="title-2 text-h6">
                  <p v-html="$t('home-37')"></p>
                </div>
              </v-row>
              <v-row class="pl-2 black--text">
                <p class="text-justify mr-sm-4" v-html="$t('home-38')"></p>
              </v-row>
            </v-col>
            <v-col lg="1" xl="1"></v-col>
          </v-row>
        </v-container>
      </v-parallax>
    </section>
  </div>
</template>

<script lang="js">
import rellax from 'rellax'
import * as VanillaTilst from 'vanilla-tilt'

export default {
  name: 'Home',
  metaInfo: {
    title: 'Rapidbook - Home',
    metas: [
      {
        name: 'com.silverpop.brandeddomains',
        content: 'www.pages06.net,corlet.fr,rapidbook.fr,www.corlet.fr,www.rapidbook.fr'
      }
    ],
    script: [
      {
        src: 'https://www.sc.pages06.net/lp/static/js/iMAWebCookie.js?40625c3e-18bd96a8009-3c9d767ebba0be65b5fa68bf26a6ee93&h=www.pages06.net'
      }
    ]
  },
  props: [],
  data() {
    return {
      fullHeight: this.getFullHeight()
    }
  },
  mounted() {
    new rellax('.rellax')
    VanillaTilst.init(document.querySelectorAll('.vanilla-tilt'), {
      reverse: true,
      max: 5,
      speed: 2000,
    })
  },
  created() {
    window.addEventListener('resize', this.onResize)
  },
  destroyed() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    getFullHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xl':
          return window.innerHeight - 64
        case 'lg':
          return window.innerHeight - 64
        case'md':
          return window.innerHeight - 56
        default:
          return window.innerHeight - 56
      }
    },
    onResize() {
      this.fullHeight = this.getFullHeight()
    }
  }
}
</script>

<style scoped>
.parallax__container {
  clip: rect(0, auto, auto, 0);
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -100;
}

.parallax {
  position: fixed;
  top: 0;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  width: 100%;

  /* BG behaviour */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.jaune {
  background-color: #ffd600;
  color: #000000;
}

.title-1 {
  color: #ffd600;
  font-weight: bold;
}

.title-2 {
  color: #323032;
}

#section-0 {
  min-height: calc(100vh - 64px);
}

#section-0 .title-1 img {
  height: 15px;
}

#section-0 .produit-parallax-container {
  min-height: calc(100vh - 64px);
}

#section-0 .produit-parallax {
  background: #ffffff;
  border-left-style: solid;
  border-top-style: solid;
  border-bottom-style: solid;
  border-left-width: 50px;
  border-top-width: 50px;
  border-bottom-width: 50px;
  border-image: url("~@/assets/img/home_one_page/03_produit/element_gauche_texte.svg")
    100;
  border-bottom-left-radius: 50px;
}

#section-1 {
  min-height: calc(100vh - 64px);
}

#section-1 .bordure-gauche-container {
  height: 100%;
  min-height: calc(100vh - 64px);
}

#section-1 .bordureGauche {
  height: 70vh;
}

#section-1 .element-soustitre {
  height: 10px;
}

#section-1 .arguments img {
  max-height: 75px;
}

#section-1 .arguments img.separation {
  max-height: 15em;
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: white;
  opacity: 0.3;
}

#section-2 {
  min-height: calc(100vh - 64px);
}

#section-2 .img-top {
  width: 100%;
}

#section-2 .element-soustitre {
  height: 10px;
}

#section-2 .element-logocorlet {
  height: 75px;
}

#section-2 .element-soustitre-raisons {
  width: 55%;
}

#section-2 .raisons-choix img {
  max-height: 100px;
}

#section-3 {
  min-height: calc(100vh - 64px);
}

#section-3 .element-soustitre {
  height: 10px;
}

#section-3 .produit-parallax-container {
  min-height: calc(100vh - 64px);
}

#section-3 .produit-parallax {
  background: #ffffff;
  border-left-style: solid;
  border-top-style: solid;
  border-bottom-style: solid;
  border-left-width: 50px;
  border-top-width: 50px;
  border-bottom-width: 50px;
  border-image: url("~@/assets/img/home_one_page/03_produit/element_gauche_texte.svg")
    100;
  border-bottom-left-radius: 50px;
}

#section-3 .produit_parallax_background {
  position: absolute;
  height: 105%;
  width: 100%;
  margin-left: 0 !important;
}

#section-3 .produit_parallax_background .produit_parallax_background_white {
  background: #ffffff;
}

#section-3 .produit-parallax-container .gauche-texte {
  min-height: 100%;
  max-width: 80px;
}

#section-4 {
  min-height: calc(100vh - 64px);
}

#section-4 .bordureGauche {
  height: 70vh;
}

#section-4 .element-soustitre {
  height: 10px;
}

#section-4 .equipe_parallax {
  min-height: calc(100vh - 64px);
}

.btn-arrondi {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  border-radius: 25px 0 25px 0;
  background: #ffd600;
  color: #f5f5f5;
}

#section-4 .equipe_background {
  position: absolute;
  width: 100%;
  margin-left: 0 !important;
}

#section-4 .equipe_background .equipe_background_jaune {
  background: #ffffff;
}

#section-4 .img-equipe {
  width: 75vw;
  height: 75vw;
}

@media (min-width: 576px) {
  #section-4 .img-equipe {
    width: 75vw;
    height: 75vw;
  }

  #section-4 .equipe_background .equipe_background_jaune {
    background: #ffffff;
  }
}

@media (min-width: 768px) {
  #section-4 .img-equipe {
    width: 38vw;
    height: 38vw;
  }

  #section-4 .equipe_background .equipe_background_jaune {
    background: #ffd600;
  }
}

@media (min-width: 992px) {
  #section-4 .img-equipe {
    width: 40vw;
    height: 40vw;
  }
}

@media (min-width: 1200px) {
  #section-4 .img-equipe {
    width: 33.33vw;
    height: 33.33vw;
  }
}

@media (max-width: 768px) {
  .separation,
  .navigation {
    display: none !important;
  }
}

#section-5 {
  min-height: calc(100vh - 64px);
}

#section-5 .element-soustitre {
  height: 10px;
}

#section-5 .produit-parallax-container {
  min-height: calc(100vh - 64px);
}

#section-5 .produit-parallax {
  background: #ffffff;
  border-left-style: solid;
  border-top-style: solid;
  border-bottom-style: solid;
  border-left-width: 50px;
  border-top-width: 50px;
  border-bottom-width: 50px;
  border-image: url("~@/assets/img/home_one_page/03_produit/element_gauche_texte.svg")
    100;
  border-bottom-left-radius: 50px;
}

#section-5 .produit_parallax_background {
  position: absolute;
  height: 100%;
  width: 100%;
  margin-left: 0 !important;
}

#section-5 .produit_parallax_background .produit_parallax_background_white {
  background: #ffffff;
}

#section-5 .produit-parallax-container .gauche-texte {
  min-height: 100%;
  max-width: 80px;
}

.svg-arrow-hero-container {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.svg-arrow-hero-container img {
  position: absolute;
  bottom: 0;
  width: 100px;
}

#nav-scroll {
  position: fixed;
  right: 1vw;
  width: 50px;
  z-index: 99;
  height: 100vh;
}

#nav-scroll .nav-item .nav-link img {
  width: 25px;
  opacity: 0.2;
}

#nav-scroll .nav-item.active .nav-link img {
  opacity: 1 !important;
}
</style>

<i18n lang="json">
{
  "fr": {
    "home-1": "RAPIDBOOK : TOURNEZ LA PAGE EN<br>UN CLIC !",
    "home-2": "Le nouvel outil d'impression à la demande des professionnels de l'édition",
    "home-3": "TOURNEZ LA PAGE AVEC RAPIDBOOK",
    "home-4": "Le nouveau système d'impression à la demande",
    "home-5": "Une question primordiale d'abord, <strong>Rapidbook</strong>, c'est quoi ? Il s'agit tout bonnement de la nouvelle création du groupe Corlet, un <strong>système d'impression à la demande</strong>, ou <strong>Print on Demand (PoD)</strong> pour les anglophones, destiné aux éditeurs.",
    "home-6": "Pas d'attente pour le devis, il est <strong>immédiat et en ligne</strong>. Quant aux prix, ils sont <strong>dégressifs</strong>: plus la commande est importante et plus le prix est avantageux. Le reste, c'est nous qui nous en occupons avec une livraison assurée en moyenne 5 jours plus tard (ouvrés - hors contraintes de rendez-vous diffuseurs).",
    "home-7": "Avec Rapidbook, plus de problème de livres qui s'accumulent en réserve et qui prennent la poussière : d'un seul clic, les éditeurs peuvent refaire leur stock quand ils en ont besoin, même pour un <strong>seul exemplaire</strong>, et cela <strong>24h/24, 7j/7</strong>.",
    "home-8": "<strong>Simple</strong> vous avez dit ?",
    "home-9": "<strong>LIVRAISON EN 48H/72H</strong>",
    "home-10": "Nos partenaires nous permettent d'assurer une livraison en moins de 72h dès la fin de fabrication.",
    "home-11": "<strong>À PARTIR DE 1 EXEMPLAIRE</strong>",
    "home-12": "Fini le stock ! Il est possible de commander à partir d'un exemplaire.",
    "home-13": "<strong>PRIX DÉGRESSIFS</strong>",
    "home-14": "Plus la commande est importante, plus le prix est avantageux.",
    "home-15": "NOTRE HISTOIRE",
    "home-16": "Rapidbook, une nouvelle page de l'histoire Corlet",
    "home-17": "Derrière Rapidbook, il y a le groupe Corlet. Sa réputation n'est plus à faire aujourd'hui: créée en <strong>1960</strong>, l'ancienne petite entreprise a petit à petit gravi les échelons jusqu'à devenir <strong>4<sup>e</sup> imprimerie de France.</strong>",
    "home-18": "Un hasard ? Non, car l'histoire du groupe Corlet montre bien que c'est grâce à sa <strong>détermination</strong> et ses nombreuses <strong>innovations</strong> qu'il a réussi à acquérir ce statut. Innovations dont le site Rapidbook fait aujourd'hui partie. Bénéficiant à la fois du <strong>support technique</strong> du groupe Corlet, d'un <strong>savoir-faire</strong> déjà présent grâce à plusieurs décennies d'expérience et d'une équipe <strong>dynamique et performante</strong>, nul doute que Rapidbook est un site déjà à la page.",
    "home-19": "Vous l'aurez donc compris le groupe Corlet et Rapidbook travaillent main dans la main pour vous apporter un produit abouti, complet, et de qualité : un vrai <strong>best-seller</strong> en somme.",
    "home-20": "4 BONNES RAISONS<br />DE NOUS CHOISIR",
    "home-21": "<strong>60 ANS D'EXPÉRIENCE</strong>",
    "home-22": "Corlet a beau être sexagénaire, ça ne l'empêche pas d'être toujours là pour ses clients.",
    "home-23": "<strong>3<sup>e</sup> IMPRIMEUR FRANÇAIS</strong>",
    "home-24": "Avec 2 millions d'imprimés et 150 commandes par jour, Corlet se hisse sur le podium de l'impression de livres ! ",
    "home-25": "<strong>360 COLLABORATEURS</strong>",
    "home-26": "Armé de tous nos collaborateurs, ensemble nous ferons devenir vos livres réalité.",
    "home-27": "<strong>MADE IN NORMANDIE</strong>",
    "home-28": "Nous favorisons une production locale pour une meilleure protection de la planète. C'est beau non ?",
    "home-29": "POUR L'ÉPANOUISSEMENT DES ÉDITEURS",
    "home-30": "Rapidbook, une gestion en toute liberté",
    "home-31": "À partir d'un exemplaire chez Rapidbook, chaque livre est fait <strong>sur mesure.</strong> Du petit livre de poche indispensable en cas de manque de place (11 x 18 cm) au pavé rempli de connaissances infinies (21 x 29,7 cm), c'est à vous de choisir le format. À votre libre choix aussi, le nombre de pages de votre précieux ouvrage, à partir de 64 pages.",
    "home-32": "Vous voulez donner un certain style à votre livre ? N'hésitez pas à choisir votre propre papier entre bouffant 80 g blanc ou offset 80 g. Enfin, vous pouvez également opter pour un pelliculage mat ou brillant. Grâce à Rapidbook, ayez enfin un livre <strong>à votre image</strong> !",
    "home-33": "NOTRE ÉQUIPE",
    "home-34": "Pour vous accompagner au mieux",
    "home-35": "Notre équipe, c'est avant tout des personnes déjà rodées au monde de l'impression après avoir travaillé plusieurs années au sein du groupe Corlet. <strong>Disponibles, organisées, efficaces</strong>, elles sont même capables de lire entre les lignes. À votre écoute et prêt à répondre à toutes vos questions, n'hésitez pas à les contacter en cas de problème !",
    "home-36": "UNE PRODUCTION ÉCO-RESPONSABLE",
    "home-37": "Jeter des mots sur le papier, oui, mais qu'en est-il de le jeter ?",
    "home-38": "Dans la digne lignée de Corlet, Rapidbook s'inscrit aussi comme une entreprise dédiée à la <strong>protection de l'environnement</strong>. Utilisant un papier issu d'une forêt gérée durablement et bénéficiant à la fois de la certification <strong>PEFC</strong> et du <strong>label Imprim'Vert</strong>, Rapidbook a à coeur de vous présenter un produit éco-responsable. Mais cet engagement va au-delà du papier, Rapidbook a aussi pris ses quartiers chez Corlet Numeric, un <strong>bâtiment écologique</strong> dont la toiture est équipée de 777m² de <strong>panneaux photovoltaïques</strong> et où les eaux usées sont traitées. Enfin, Rapidbook privilégie aussi le <strong>Made in France</strong> car, comme vous l'aurez deviné, toute notre production prend place en <strong>Normandie</strong> !"
  },
  "en": {
    "home-1": "RAPIDBOOK: TURN THE PAGE IN<br>A CLICK!",
    "home-2": "The new print-on-demand tool for publishing professionals",
    "home-3": "TURN THE PAGE WITH RAPIDBOOK",
    "home-4": "The new print-on-demand system",
    "home-5": "First things first, what is <strong>Rapidbook</strong>? It is simply the new creation of the Corlet group, a <strong>print-on-demand</strong> system, or <strong>PoD</strong> for English speakers, intended for publishers.",
    "home-6": "No waiting for the quote, it is <strong>immediate and online</strong>. As for the prices, they are <strong>decreasing</strong>: the larger the order, the more advantageous the price. We take care of the rest with delivery guaranteed on average 5 days later (working days - excluding distributor appointment constraints).",
    "home-7": "With Rapidbook, no more problems with books piling up in stock and gathering dust: with a single click, publishers can replenish their stock when they need it, even for a <strong>single copy</strong>, and this <strong>24/7</strong>.",
    "home-8": "Did you say <strong>simple</strong>?",
    "home-9": "<strong>DELIVERY IN 48H/72H</strong>",
    "home-10": "Our partners allow us to ensure delivery in less than 72 hours from the end of manufacturing.",
    "home-11": "<strong>FROM 1 COPY</strong>",
    "home-12": "No more stock! It is possible to order from one copy.",
    "home-13": "<strong>DECREASING PRICES</strong>",
    "home-14": "The larger the order, the more advantageous the price.",
    "home-15": "OUR HISTORY",
    "home-16": "Rapidbook, a new page in Corlet's history",
    "home-17": "Behind Rapidbook is the Corlet group. Its reputation is well established today: created in <strong>1960</strong>, the former small company gradually climbed the ladder to become the <strong>4<sup>th</sup> largest printing company in France.</strong>",
    "home-18": "A coincidence? No, because the history of the Corlet group clearly shows that it is thanks to its <strong>determination</strong> and its numerous <strong>innovations</strong> that it has managed to acquire this status. Innovations of which the Rapidbook site is now a part. Benefiting from both the <strong>technical support</strong> of the Corlet group, <strong>know-how</strong> already present thanks to several decades of experience and a <strong>dynamic and efficient</strong> team, there is no doubt that Rapidbook is a site already up to date.",
    "home-19": "As you will have understood, the Corlet group and Rapidbook work hand in hand to bring you a complete, high-quality and successful product: a real <strong>best-seller</strong> in short.",
    "home-20": "4 GOOD REASONS<br />TO CHOOSE US",
    "home-21": "<strong>60 YEARS OF EXPERIENCE</strong>",
    "home-22": "Corlet may be a senior citizen, but that doesn't stop it from always being there for its customers.",
    "home-23": "<strong>3<sup>rd</sup> FRENCH PRINTER</strong>",
    "home-24": "With 2 million printed items and 150 orders per day, Corlet is on the podium of book printing!",
    "home-25": "<strong>360 EMPLOYEES</strong>",
    "home-26": "Armed with all our collaborators, together we will make your books a reality.",
    "home-27": "<strong>MADE IN NORMANDY</strong>",
    "home-28": "We favor local production for better protection of the planet. Isn't that beautiful?",
    "home-29": "FOR THE DEVELOPMENT OF PUBLISHERS",
    "home-30": "Rapidbook, management in complete freedom",
    "home-31": "Starting from one copy at Rapidbook, each book is <strong>custom-made.</strong> From the small paperback essential in case of lack of space (11 x 18 cm) to the tome filled with infinite knowledge (21 x 29.7 cm), it's up to you to choose the format. You can also freely choose the number of pages of your precious book, starting from 64 pages.",
    "home-32": "Want to give your book a certain style? Don't hesitate to choose your own paper between bulky 80 g white or offset 80 g. Finally, you can also opt for a matt or gloss lamination. Thanks to Rapidbook, finally have a book <strong>in your image</strong>!",
    "home-33": "OUR TEAM",
    "home-34": "To best support you",
    "home-35": "Our team is above all people who are already familiar with the world of printing after having worked for several years within the Corlet group. <strong>Available, organized, efficient</strong>, they are even able to read between the lines. At your disposal and ready to answer all your questions, do not hesitate to contact them in case of a problem!",
    "home-36": "AN ECO-RESPONSIBLE PRODUCTION",
    "home-37": "Throwing words on paper, yes, but what about throwing it away?",
    "home-38": "In the worthy lineage of Corlet, Rapidbook is also a company dedicated to the <strong>protection of the environment</strong>. Using paper from a sustainably managed forest and benefiting from both <strong>PEFC</strong> certification and the <strong>Imprim'Vert label</strong>, Rapidbook is committed to presenting you with an eco-responsible product. But this commitment goes beyond paper, Rapidbook has also taken up residence at Corlet Numeric, an <strong>ecological building</strong> whose roof is equipped with 777m² of <strong>photovoltaic panels</strong> and where wastewater is treated. Finally, Rapidbook also favors <strong>Made in France</strong> because, as you may have guessed, all our production takes place in <strong>Normandy</strong>!"
  }
}
</i18n>
